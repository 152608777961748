import React, { useEffect, useState } from "react";
import "./Courses.css";
import { Col, Row, Card, Pagination, message,Input } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BaseUrl, CommuncationBot } from "../Constants/Constants";
import { useStateValue } from "../../Reducer/reducer";

const Courses = () => {
  const { Meta } = Card;
  const { Search } = Input;
  const navigate = useNavigate();

  const [
    { UserID, companyId },
    dispatch,
  ] = useStateValue();

  const [courses, setCourses] = useState([]);
  const [coursesarr, setCoursesarr] = useState([]);
  const [loading, setLoading] = useState(true);
const [InputVal, setInputVal] = useState('')


  console.log(companyId, 'companyId')

  useEffect(() => {

    if (UserID != undefined || UserID != "") {

      setLoading(true);

      let body = {
        userId: UserID
      }

      axios.post(`${BaseUrl}course-mapping/getAllCourses`, body)

        .then((res) => {
          if (res.data.statuscode === 200) {
            setCourses(res.data.data);
            setCoursesarr(res.data.data)
            setLoading(false)
          }
          else {
            setCourses([]);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err.message);
          setLoading(false);
          message.success('Please login again to continue!')
          navigate('/login')
          setCourses([])
        })
        
    }
    else {
      message.success('Please login again to continue!')
      navigate('/login')
    }

  }, [])

  const handleCommunication = () => {
    const Profile = localStorage.getItem("Profile");
    const ParsedProfile = JSON.parse( Profile);
    window.location.href=`${CommuncationBot}/${ParsedProfile.email}`
    console.log(ParsedProfile.email, 'userProfile')
  }

  const handleCard = (e) => {
    navigate(`/courses/${e.course_id}`);
  };

  const handleChange = (e) => {
    const value = e.target.value;
  console.log(value,"inputval")
    // Check if the input is empty
    if (value.trim() === '') {
      setCoursesarr(courses); 
    } else {
      // If not empty, filter the array based on the search term
      const filteredResults = courses.filter((item) =>
        item.course_title.toLowerCase().includes(value.toLowerCase())
      
    );
      setCoursesarr(filteredResults);
      console.log(filteredResults,"filtered")
    }
  };
  
  return (

    <div className="Courses-main">

    
      {/* <Input
      placeholder="Search Course"
      onChange={(e) => handleChange(e)}
      className="SearchInput"
      >

      </Input> */}
     
      <Search
      placeholder="Search Course"
      onChange={(e) => handleChange(e)}
      className="SearchInput"
      allowClear
                  />
      <h2 className="Course-heading">Active Courses</h2>


      {
        loading  ?
          <>
            <div className='Loader' >
              <img src='/Loader/Ellipsis-2.9s-200px.svg'>
              </img>
            </div>
          </>
          :
          <Row className="manageChatbotRow">
            {  coursesarr.length < courses.length    ? 
      <></>
      :
            <div className="Course-card">
    
              <Col span={6} className="manageBotCardCol">
                        <Card
                          onClick={() => handleCommunication()}
                          hoverable
                          className="cards"
                          style={{
                            width: 270,
                            padding: 2,
                          }}
                          cover={
                            <img
                              alt="Bot Image"
                              // src={item.chat_icon == "" ? "icons\CourseIcons.png" : item.chat_icon}
                              src={ "/icons/CourseIcons.png" }
                              height={180}
                            />
                          }
                        >
                          <Meta
                            title='Language & Communication'
                          />
                        </Card>
              </Col>
            </div>
              }
            {courses.length != 0 ? (
              <>
                {coursesarr.map((item) => {
                  return (
                    <div className="Course-card">
                      <Col span={6} className="manageBotCardCol">
                        <Card
                          onClick={() => handleCard(item)}
                          hoverable
                          className="cards"
                          style={{
                            width: 270,
                            padding: 2,
                          }}
                          cover={
                            <img
                              alt="Course Image"
                              // src={item.chat_icon == "" ? "icons\CourseIcons.png" : item.chat_icon}
                              src={item.course_img !== null && item.course_img !== '' ? item.course_img : "/icons/CourseIcons.png" }
                              height={180}
                            />
                          }
                        >
                          <Meta
                            title={
                              item.course_title != ""
                                ? item.course_title
                                : `Unnamed Course`
                            }
                          // title={'Bot' + " " + (key + 1)}
                          />
                        </Card>
                      </Col>
                    </div>
                  );
                })}
              </>
            ) : (
              // <>No items</>
              <></>
            )}
          </Row>
      }
    </div>
  );
};

export default Courses;
