import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Notification.css'; // Assume you have appropriate styling
import { BaseUrl } from '../Constants/Constants';
import { useNavigate } from 'react-router-dom';
import NoNotificationsImage from '../../Assets/notification1';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChalkboardTeacher, faBook,faTimes } from '@fortawesome/free-solid-svg-icons';
import { formatDistanceToNow, parseISO,format } from 'date-fns';
import { enIN } from 'date-fns/locale';
import { toZonedTime } from 'date-fns-tz';
import { formatInTimeZone } from 'date-fns-tz';


const Notification = ({ userId, onNotificationsViewed ,onClose}) => {
  const [notifications, setNotifications] = useState([]);
  const [courseNotifications, setCourseNotifications] = useState([]);
  const [courses, setCourses] = useState([]);

  
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const convertToIST = (dateString) => {
    const date = new Date(dateString);
    const istOffset = 5.5 * 60 * 60 * 1000; // IST is UTC+5:30
    return new Date(date.getTime() + istOffset);
  };

  const displayRelativeTime = (createdAt) => {
    const istDate = convertToIST(createdAt);
    return formatDistanceToNow(istDate, { addSuffix: true });
  };
  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(`${BaseUrl}training-mapping/notifications/${userId}`);
        if (response.data.status === 'Ok' && response.data.statuscode === 200) {
            const sortedNotifications = response.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            setNotifications(sortedNotifications);
          await axios.post(`${BaseUrl}training-mapping/update-notifications`, { userId });
        } else {
          setNotifications([]);
        }
      } catch (err) { 
        setError('Failed to fetch notifications');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, [userId]);

  ////courses
//   useEffect(() => {
//     const fetchCourseNotifications = async () => {
//       try {
//         const response = await axios.get(`${BaseUrl}course-mapping/notifications/${userId}`);
//         if (response.data.status === 'Ok' && response.data.statuscode === 200) {
//           setCourseNotifications(response.data.data);
//           console.log(courseNotifications)
//           await axios.post(`${BaseUrl}course-mapping/update-notifications`, { userId });
//         } else {
//           setCourseNotifications([]);
//         }
//       } catch (err) { 
//         setError('Failed to fetch notifications');
//         console.error(err);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchCourseNotifications();
//   }, [userId]);

useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(`${BaseUrl}courses/findAll`);
        if (response.data.status === 'Ok' && response.data.statuscode === 200) {
          setCourses(response.data.data);
        } else {
          setCourses([]);
        }
      } catch (err) {
        setError('Failed to fetch courses');
        console.error(err);
      }
    };

    fetchCourses();
  }, []); // Empty dependency array ensures this runs only once when the component mounts

useEffect(() => {
    if (courses.length > 0) {
      const fetchCourseNotifications = async () => {
        try {
          const response = await axios.get(`${BaseUrl}course-mapping/notifications/${userId}`);
          if (response.data.status === 'Ok' && response.data.statuscode === 200) {
            const updatedCourseNotifications = response.data.data.map(courseNotification => {
              const matchedCourse = courses.find(course => course.course_id === courseNotification.course_id);
              return {
                ...courseNotification,
                course_title: matchedCourse ? matchedCourse.course_title : 'Unknown Course',
              };
            });
            setCourseNotifications(updatedCourseNotifications);
            await axios.post(`${BaseUrl}course-mapping/update-notifications`, { userId });
          } else {
            setCourseNotifications([]);
          }
        } catch (err) {
          setError('Failed to fetch course notifications');
          console.error(err);
        } finally {
          setLoading(false);
        }
      };

      fetchCourseNotifications();
    }
  }, [userId, courses]);

  const handleTrainingClick = async (trainingId) => {
    try {
      const response = await axios.post(`${BaseUrl}trainings/getCourseByTraining`, { training_id: trainingId });
      if (response.data.status === 'Ok' && response.data.statuscode === 200) {
        const courseId = response.data.data.course_id.course_id;
       
        await axios.post(`${BaseUrl}training-mapping/delete-notifications`, { userId, trainingId });
        navigate(`/training/${trainingId}/${courseId}`);
        onClose();
        window.location.reload();
      } else {
        console.error('Failed to fetch course ID');
      }
    } catch (err) {
      console.error('Error fetching course ID', err);
    }
  };
  const handleCourseClick = async (courseId) => {
    try {
      // Make an API call to delete the course notification for the specific courseId and userId
      await axios.post(`${BaseUrl}course-mapping/delete-notifications`, { userId, courseId });
  
      // Navigate to the course details page
      navigate(`/courses/${courseId}`);
      
      // Close the notifications panel and refresh the page if needed
      onClose();
      window.location.reload();
    } catch (err) {
      console.error('Error handling course click', err);
    }
  };

  const handleClearAll = async () => {
    try {
      await axios.post(`${BaseUrl}training-mapping/delete-all-notifications`, { userId });
      await axios.post(`${BaseUrl}course-mapping/delete-all-notifications`, { userId });
      setNotifications([]);
      setCourseNotifications([]);
      onClose(); // Optionally close the notification panel
    } catch (err) {
      console.error('Error clearing all notifications', err);
    }
  };

  if (loading) return <p>Loading notifications...</p>;
  if (error) return <p>{error}</p>;

  const combinedNotifications = [...notifications, ...courseNotifications].sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );

  return (
    <div className="notification-popup">
         <div className="notification-header">
    <h3>Notifications <span className="notification-icon">🔔</span></h3>
    <span className="close-icon" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </span>
        </div>
    {combinedNotifications.length > 0 ? (
      <ul className="notification-list">
        {combinedNotifications.map((notification) => (
          <li
            className="notification-card"
            key={notification.training_mapping_id || notification.course_mapping_id}
            onClick={() => notification.training_id ? handleTrainingClick(notification.training_id) : handleCourseClick(notification.course_id)}
          >
            <div className="notification-content">
              <span className="notification-icon">
              <FontAwesomeIcon icon={notification.training_id ? faChalkboardTeacher : faBook} />
              </span>
              <p><strong>{notification.training_id ? 'Training' : 'Course'}:</strong> {notification.training_title || notification.course_title} Assigned to you</p>
              <p className="click-to-view">
              {/* {displayRelativeTime(notification.created_at)} */}
              <p>Click to view</p>
              </p>
            </div>
          </li>
        ))}
      </ul>
    )  : (
      <div className="no-notifications">
        <img src={NoNotificationsImage} alt="No Notifications" style={{ width: '200px', height: '200px' }} />
        <p>No new notifications</p>
      </div>
    )}
    {combinedNotifications.length > 0 && (
  <button className="clear-all-button" onClick={handleClearAll}>Clear All</button>
)}
  </div>
);
};



export default Notification;
