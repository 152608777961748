import React, { useState, useEffect } from 'react'
import { Button } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { AssesmentUrl, BaseUrl } from '../../../Constants/Constants'
import { useStateValue } from '../../../../Reducer/reducer'
import ReviewAnswerModule from './ReviewAnswerModule/ReviewAnswerModule'
import McqDescResult from './McqDescResult'

const McqResult = () => {

  const navigate = useNavigate()
  const { id, assessmentId } = useParams();
  const [
    { UserID }
  ] = useStateValue();
  const [Score, setScore] = useState('')
  const [Data, setData] = useState('')

  const getScoreColor = () => {
    if (Score >= (Data.Number_of_questions * 0.8)) {
      return '#4caf50'; // Green for high scores (80% and above)
    } else if (Score >= (Data.Number_of_questions * 0.5)) {
      return '#ffa726'; // Orange for medium scores (50% to 79%)
    } else {
      return '#ff4c4c'; // Red for low scores (below 50%)
    }
  };
  useEffect(() => {
    let body = {
      assessment_id: assessmentId
    }
    axios
      .post(`${BaseUrl}assesment/Assesment_details`, body)
      .then((res) => {
        setData(res.data.data)
        console.log(res.data.data, "len")
      })
      .catch((err) => {

      })
      .finally((res) => {

      })
  }, [])

  useEffect(() => {
    let body = {
      user_id: UserID,
      course_id: id,
      assessment_id: assessmentId
    }
    axios
      .post(`${AssesmentUrl}Get_score`, body)
      .then((res) => {
        console.log(res.data.scores[0], 'res');
        const score = res.data.scores[0]?.score;
        if (score !== undefined) {
          setScore(score);
          console.log(score, 'score');
        }
      })
      .catch((err) => {
        console.log(err)
      })

  }, [])

  const handleDashboard = () => {
    navigate(`/`)
  }
  return (
    <div>
      <div className='mcq-res-MainDiv'>
        <div className="result-card">
          <h1 className="thanks">🎉 Thank You!</h1>
          <p className="completed">Test Completed Successfully</p>

          {
            Data.assessment_type === 'mcq with description' ?
              <p className="completed">Scores will be sent to you in mail</p>
              :

              <h2 className="score">
                Your Score: <span style={{ color: getScoreColor() }}>{Score}/{Data.Number_of_questions}</span>
              </h2>
          }


          <Button
            className="back-to-dashboard"
            type="primary"
            onClick={handleDashboard}
          >
            Go to Dashboard
          </Button>
        </div>

      </div>
      <ReviewAnswerModule />
    </div>
  )
}

export default McqResult