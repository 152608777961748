const storedUserId = JSON.parse(localStorage.getItem("Profile"))
const companyId = JSON.parse(localStorage.getItem("companyId"))

export const initialState={
    
    IsAuthenticated : localStorage.getItem("IsAuthenticated"),
    UserID:localStorage.getItem("UserID"),
    Questions:{},
    UserID:storedUserId?.user_id,
    companyId : companyId
}

export const actionTypes = {
    
    SET_ISAUTHENTICATED : 'SET_ISAUTHENTICATED',
    SET_UserID:'SET_UserID',
    SET_Questions:'SET_Questions',
    SET_COMPANYID : 'SET_COMPANYID'
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.SET_ISAUTHENTICATED:
            {
                return{
                    ...state,
                    IsAuthenticated:action.IsAuthenticated
                }
            }
            case actionTypes.SET_UserID:
                {
                    return{
                        ...state,
                        UserID:action.UserID
                    }
                }
                case actionTypes.SET_Questions:
                    {
                        return{
                            ...state,
                            Questions:action.Questions
                        }
                    }
                case actionTypes.SET_COMPANYID:
                {
                    return{
                        ...state,
                        companyId:action.companyId
                    }
                }
                
            default:
                return state;
    }
}
export default reducer;