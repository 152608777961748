import React, { useEffect, useRef } from 'react';
import { Column } from '@antv/g2plot';

const SalesBarChart = (data) => {
    const chartContainer = useRef(null);

    console.log(data.data,"chandata")
    // const data = [
    //     { type: 'You', marks: 38 },
    //     { type: 'Median', marks: 52 },
    //     { type: 'Max', marks: 61 },
    // ];

    useEffect(() => {
        const columnPlot = new Column(chartContainer.current, {
            data:data.data,
            xField: 'type',
            yField: 'marks',
            columnWidthRatio: 0.3,
            label: {
                position: 'middle',
                style: {
                    fill: '#FFFFFF',
                    opacity: 0.6,
                },
            },
            xAxis: {
                label: {
                    autoHide: false,
                    autoRotate: false,
                },
            },
            meta: {
                type: { alias: '' },
                marks: { alias: 'Marks' },
            },
        });
        
        columnPlot.render();

        return () => {
            columnPlot.destroy(); // Cleanup chart on component unmount
        };
    }, []);

    return <div style={{width:350}} ref={chartContainer}></div>;
}

export default SalesBarChart;
