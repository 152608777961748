import React, { useState, useEffect } from 'react'
import { Col, Row, Input, Button, message, Form, notification } from 'antd'
import './SignUp.css'
import { useNavigate } from 'react-router';
import { MailOutlined, LockOutlined } from '@ant-design/icons'
import { BaseUrl } from '../Constants/Constants';
import { useStateValue } from '../../Reducer/reducer';
import { actionTypes } from '../../Reducer/StateProvider';
import axios from 'axios';

const SignUp = () => {
  const [
    { },
    dispatch,
  ] = useStateValue();
  
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [email, setEmail] = useState('');
  const [FirstName, setFirstName] = useState("")
  const [LastName, setLastName] = useState("")
  const [EmailStatus, setEmailStatus] = useState('');
  const [password, setPassword] = useState('');
  const [pwdStatus, setPwdStatus] = useState('');
  const openNotificationWithIconWarning = (type) => {
    api['warning']({
      message: "Warning!!!",
      description:
        type,
    });
  };
  const openNotificationWithIconSuccess = (type) => {
    console.log('getsSuccess')
    api['success']({
      message: "Success!!!",
      description:
        type,
    });
  };
  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    // Email validation regex pattern
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(value);

    // Update email status based on validation
    setEmailStatus(isValidEmail ? 'success' : 'error');
  };
  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);

    // Password validation regex pattern
    const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
    const isValidPassword = passwordRegex.test(value);

    // Update password status based on validation
    setPwdStatus(isValidPassword ? 'success' : 'error');
    console.log(isValidPassword, "isValidPassword")
  };
  const handleSignup = () => {
    let Body = {

      "userName": FirstName+LastName,
      "userPassword": password,
      "Email": email,
      "companyid":"c32f1e05-4386-4631-98fe-ca7f6d2cc0cd"

    }
    if (email && password && pwdStatus == "success") {
      
      axios
        .post(`${BaseUrl}auth/signup`, Body)
        .then((res) => {
          console.log(res, "log")
          if(res.data.status === 204){
            
          openNotificationWithIconWarning('Invalid Credentials');
          }
          else{
            openNotificationWithIconSuccess('Signed in Sucessfully')
            localStorage.setItem("IsAuthenticated", "true");
            dispatch({
              type: actionTypes.SET_ISAUTHENTICATED,
              IsAuthenticated: "true"
            })
            navigate("/")
          }
        })
        .catch((err) => {
          // openNotificationWithIconWarning('Invalid Credentials');
          console.log(err, "log")
          if(err.status == 409){
            openNotificationWithIconWarning('User Already exist');
          }
        })
    }
    else{
      
      openNotificationWithIconWarning('Please enter valid Credentials');
    }
  }
  const handleForgotPass = () => {
    navigate("/forgotpassword")
  }
  const handleLogin = () => {
    navigate("/login")
  }
  return (
    <div> {contextHolder}
    <Row className='LoginRow'>
      <Col
        >
          <div className='signup  '>
        <div className='logindiv'>
        <div  className='LogoDiv'>
          <img src='Logo\Logo Svg OG 3.png' className='loginImage' alt='Logo' />
          </div>
          <p className='LoginH1'>
            Signup Portal
          </p>
          <Row>

         
          <Col span={11}>
                  <Input
                className='loginInput'
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="First Name" />
                  </Col>
                  <Col span={11} offset={2} >
                  <Input
                className='loginInput'
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Last Name" />
                  </Col>
                  </Row>
          <Input
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                document.getElementById('passwordInputField').focus();
              }
            }}
            className='loginInput'
            placeholder='Email ID'
            onChange={handleEmailChange}
            status={!email ? EmailStatus : ''}
          />
          <Form.Item className='PasswordForm' hasFeedback validateStatus={pwdStatus}>

            <Input.Password
              id='passwordInputField'
              onKeyDown={(e) => {
                if (e.key == 'Enter') handleLogin();
              }}
              className='loginInput'
              placeholder='Password'
              type='password'
              onChange={handlePasswordChange}
              status={!password ? pwdStatus : ''}
            />
          </Form.Item>


        </div>
        <div className='forgotpassDiv'>
          <Button
            onClick={handleForgotPass}
            className='forgotpassword' size='small' type='link'>
            Forgot Password?
          </Button>
            <Button
            type='primary'
              onClick={handleSignup}
              className='LoginButton'>
              SignUp
            </Button>
        </div>
        <div>
          <p className='ortag'>------------ or ------------</p>
         
            <Button
              onClick={handleLogin}
              className='signUpButton'>
              Login
            </Button>

          

            </div>
        </div>
      </Col>
    </Row>
    </div>
  )
}

export default SignUp