import { Button, Card, Radio, Space, message } from 'antd'
import React, { useEffect, useState } from 'react'
import './ReviewAnswerModule.css'
import { useNavigate, useParams } from 'react-router'
import { AssesmentUrl } from '../../../../Constants/Constants'
import axios from 'axios'

const ReviewAnswerModuleTraining = () => {

  const navigate = useNavigate();

  const { id, courseId, assessmentId } = useParams();

  const profileDetails  = JSON.parse(localStorage.getItem('Profile'));
  const userId = profileDetails.user_id;

  const [ reviewData, setReviewData ] = useState([]);

  useEffect(() => {

    let reviewAnswersUrl = `${AssesmentUrl}mcq_review_answers_training`

    let body = {
      course_id: "string",
      assessment_id: assessmentId,
      user_id: userId,
      training_id: id
    }

    axios.post(reviewAnswersUrl, body)

    .then(( res ) => {
      console.log(res, 'reviewAnswersRes');

      let response = res.data;

      if( response.status === 200 ){
        setReviewData(response.review_answers_details);
      } else{
        setReviewData([]);
      }

    }) .catch( err => {
      message.error(err.message);
    })

  }, [])

  const radioJson = [
    {
      questionId: 'mcqid-1',
      question: "What is HIDA Scan ?",
      option: [
        {
          label: 'To Image lungs',
          value: 1,
        },
        {
          label: 'To access brain tumors',
          value: 2,
        },
        {
          label: 'To measure bone density',
          value: 3,
        },
      ],
      userAnswer: 1,
      correctAnswer: 2,
      isCorrect: false
    },
    {
      questionId: 'mcqid-2',
      question: "What is HIDA Scan ?",
      option: [
        {
          label: 'To Image lungs',
          value: 1,
        },
        {
          label: 'To access brain tumors',
          value: 2,
        },
        {
          label: 'To measure bone density',
          value: 3,
        },
      ],
      userAnswer: 1,
      correctAnswer: 1,
      isCorrect: true
    },
    {
      questionId: 'mcqid-3',
      question: "What is HIDA Scan ?",
      option: [
        {
          label: 'To Image lungs',
          value: 1,
        },
        {
          label: 'To access brain tumors',
          value: 2,
        },
        {
          label: 'To measure bone density',
          value: 3,
        },
      ],
      userAnswer: 2,
      correctAnswer: 1,
      isCorrect: false
    },
    {
      questionId: 'mcqid-4',
      question: "What is HIDA Scan ?",
      option: [
        {
          label: 'To Image lungs',
          value: 1,
        },
        {
          label: 'To access brain tumors',
          value: 2,
        },
        {
          label: 'To measure bone density',
          value: 3,
        },
      ],
      userAnswer: 2,
      correctAnswer: 3,
      isCorrect: false
    },
    {
      questionId: 'mcqid-5',
      question: "What is HIDA Scan ?",
      option: [
        {
          label: 'To Image lungs',
          value: 1,
        },
        {
          label: 'To access brain tumors',
          value: 2,
        },
        {
          label: 'To measure bone density',
          value: 3,
        },
      ],
      userAnswer: 1,
      correctAnswer: 1,
      isCorrect: true
    },
  ]


  const handleDashboard = () => {
    navigate(`/`)
  }


  return (
    <div className='reviewModuleMainDiv'>
      <h2 className='reviewAnswerClass'>Review Your Answers</h2>
      {
        reviewData.length != 0 && reviewData.map((item, index) => (
          <Card key={index} className={item.isCorrect ? 'correctAnswerCard' : 'wrongAnswerCard'}>
            <p className={item.isCorrect ? 'correctAnswerResultType' : 'wrongAnswerResultType'}>{item.isCorrect ? 'Correct Answer' : 'Incorrect Answer'}</p>
            <p>{item.question}</p>
            <Radio.Group value={item.userAnswer}>
              <Space direction='vertical' >
                {
                  item.option.map((opt, index) => {

                    const isUserAnswer = opt.value === item.userAnswer;
                    const isCorrectAnswer = opt.value === item.correctAnswer;
                    console.log(opt.value, 'optValue', item.userAnswer)

                    let className = '';
                    if (!item.isCorrect && isUserAnswer) {
                      className = 'radio-incorrect';
                    } else if (isCorrectAnswer) {
                      className = 'radio-green';
                    }

                    return (
                      <Radio
                        className={className}
                        key={opt.value}
                        value={opt.value}
                      // disabled={isCorrectAnswer}
                      >{opt.label}</Radio>
                    )
                  })
                }
              </Space>
            </Radio.Group>
          </Card>
        ))
      }
      <Button className='DashboardButton'
        type='primary' onClick={handleDashboard}
      >
        Go To Dashboard
      </Button>
    </div>
  )
}

export default ReviewAnswerModuleTraining