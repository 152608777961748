import React, { useEffect, useRef, useState } from 'react';
import { Column } from '@antv/g2plot';
import { BaseUrl } from '../Constants/Constants';

const WeeklyLoginTimeChart = ({ userId }) => {
  const [data, setData] = useState([]);
  const chartRef = useRef(null);

  useEffect(() => {
    const fetchWeeklyTimes = async () => {
      try {
        const response = await fetch(`${BaseUrl}user-login-time/weekly-times/${userId}`);
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error('Error fetching weekly times:', error);
      }
    };

    fetchWeeklyTimes();
  }, [userId]);

  useEffect(() => {
    if (data.length > 0 && chartRef.current) {
      const chart = new Column(chartRef.current, {
        data,
        xField: 'day',
        yField: 'totalTime',
        label: {
          position: 'middle',
          style: {
            fill: '#FFFFFF',
            opacity: 0.6,
          },
        },
        xAxis: {
          tickInterval: 1,
          label: {
            autoHide: true,
            autoRotate: false,
            formatter: (v) => new Date(v).toLocaleDateString('en-US', { weekday: 'short' }),
          },
        },
        yAxis: {
          max: 24,
          label: {
            formatter: (v) => `${v} hrs`,
          },
        },
        meta: {
          day: { alias: 'Day of Week' },
          totalTime: { alias: 'Total Time (hours)' },
        },
      });

      chart.render();

      // Cleanup chart on component unmount
      return () => chart.destroy();
    }
  }, [data]);

  return <div ref={chartRef} style={{ height: '400px', width: '100%' }} />;
};

export default WeeklyLoginTimeChart;
