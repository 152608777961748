import React, { useState, useEffect } from 'react';
import {notification } from 'antd';
import './Hrms.css';
import { useNavigate, useParams } from "react-router-dom";
import { useStateValue } from '../../Reducer/reducer';
import { BaseUrl } from '../Constants/Constants';
import axios from 'axios';
import { actionTypes } from '../../Reducer/StateProvider';

const Hrms = () => {
  const [ {IsAuthenticated},  dispatch ] = useStateValue();
  let params = useParams();
  useEffect(() => {
    // if (IsAuthenticated=="true") {
    //   navigate('/');
    // }
    // else{
      var decodedStringAtoB = atob(params.id);
      let email = decodedStringAtoB;      
      console.log(email,"emaillll")
      let body = {
        "email": email.toLowerCase()
      }
      console.log(email,"emaillll")
      if (email) {
        axios
          .post(`${BaseUrl}auth/hrmsLogin`, body)
          .then((res) => {
            if (res.data.status === 204) {
              openNotificationWithIconWarning('Invalid Credentials');
            }
            else {
              openNotificationWithIconSuccess('Logged in Sucessfully')
              localStorage.setItem("Profile", JSON.stringify(res.data));
              localStorage.setItem("IsAuthenticated", "true");
              dispatch({
                type: actionTypes.SET_ISAUTHENTICATED,
                IsAuthenticated: "true"
              })
              dispatch({
                type: actionTypes.SET_UserID,
                UserID: res.data.user_id
              })
              navigate('/')
            }
          })
          .catch((err) => {
            console.log(err, "log")
          })
      }
      else {  
        openNotificationWithIconWarning('Invalid Credentials');
      }
    // }
  }, []);

  
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIconWarning = (type) => {
    api['warning']({
      message: "Warning!!!",
      description:
        type,
    });
  };
  const openNotificationWithIconSuccess = (type) => {
    api['success']({
      message: "Success!!!",
      description:
        type,
    });
  };

  return (
    <>
    {contextHolder}
    </>
  )
}

export default Hrms