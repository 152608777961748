import React, { useRef, useState, useEffect } from 'react'
import { LaptopOutlined, NotificationOutlined, UserOutlined } from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, Col, Row } from 'antd';
import './Pages.css'
import ReactPlayer from 'react-player'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useParams, useNavigate } from 'react-router';
import { Player, ReplayControl, ControlBar, BigPlayButton } from 'video-react';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import axios from 'axios';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { Document, Page, pdfjs } from "react-pdf";
import ArticleIcon from '@mui/icons-material/Article';
import { BaseUrl } from '../Constants/Constants';


const Pages = () => {
    const { Header, Content, Sider } = Layout;
    const [courseName, setCourseName] = useState("");
    const [MaterialName, setMaterialName] = useState('');
    const [trainingMenu, settrainingMenu] = useState([]);
    const [loading, setLoading] = useState(false);
    const [PlayedSeconds, setPlayedSeconds] = useState('')
    const [Type, setType] = useState('')
    const [TotalDuration, setTotalDuration] = useState("")
    const [TrainingModule, setTrainingModule] = useState("")
    const [DocUrl, setDocUrl] = useState('');
    // const [ playing, setPlaying ] = useState(false);




    let url = window.location.href;
    const { id } = useParams();
    const { moduleId } = useParams();
    const navigate = useNavigate();
    const intervalRef = useRef(null);
    const playerRef = useRef(null);
    const currentTimeRef = useRef(PlayedSeconds);
    console.log(moduleId, 'moduleId')

    const profileDetails = JSON.parse(localStorage.getItem('Profile'));
    const UserId = profileDetails.user_id;

    // console.log(PlayedSeconds, 'MaterialName')

    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


    const [numPages, setNumPages] = useState(null);

    const onDocumentLoadSuccess = ({ numPages }) => {
        //   setNumPages(numPages);
    };


    const saveCurrentTime = async (currentTime, fromFunction) => {


        let body = {
            userId: UserId,
            trainingModuleId: moduleId,
            timeViewed: currentTime
        }
        console.log(fromFunction, 'fromFunction', currentTime)

        try {
            await axios.post(`${BaseUrl}course-tracker/updateTimeSpentByTrainingModuleId`, body)
                .then((res) => {
                    console.log(res.data, 'updateTrainingModuleTime')
                })
        } catch (error) {
            console.error('Error saving video time', error);
        }
    };

    console.log(playerRef, 'playerRef');

    useEffect(() => {
        currentTimeRef.current = PlayedSeconds;
        console.log('currentTime updated:', PlayedSeconds);
    }, [PlayedSeconds]);


    useEffect(() => {

        let body = {
            userId: UserId,
            trainingModuleId: moduleId
        }

        axios.post(`${BaseUrl}course-tracker/getOneCourseTracker`, body)
            .then((res) => {
                if (res.data.statuscode == 200) {
                    if( res.data.data.length !== 0 && res.data.data[0].time_viewed !== '' && res.data.data[0].time_viewed !== null){

                        let convertedTime = convertTimeToSeconds(res.data.data[0].time_viewed)
                        console.log(convertedTime, 'getOneCourseTracker')
                        setTimeout(() => {
                            if (playerRef.current) {
                                setTimeout(() => {
                                    playerRef.current.seekTo(convertedTime);
                                }, 1400);
                            } else {
                                console.error('Player reference is null');
                            }
                        }, 1500)
                    }
                }
            })


        // playerRef.current.seekTo(60); // Seek to 60 seconds (1 minute)
    }, [id, moduleId]);


    const convertTimeToSeconds = (timeStr) => {
        const [minutes, seconds] = timeStr.split(':').map(Number);

        return (minutes * 60) + seconds;
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    };

    const handleProgress = ({ playedSeconds }) => {
        setPlayedSeconds(formatTime(playedSeconds));
    };

    console.log(PlayedSeconds, "progress");

    useEffect(() => {
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
                intervalRef.current = null; // Reset intervalRef on unmount
            }
        };
    }, []);

    const handlePause = () => {

        // console.log(PlayedSeconds, 'PAUSEDpLAYEDsECONDS')
        // saveCurrentTime(PlayedSeconds, 'fromHandlePause');

        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null; // Reset intervalRef
        }

        // Save the current time when paused
        saveCurrentTime(currentTimeRef.current, 'fromHandlePause');
    }

    const handlePlay = (e) => {
        // const interval = setInterval(() => {
        //     saveCurrentTime(currentTimeRef.current, 'fromHandlePlay');
        // }, 30000);

        // // Clean up the interval when the component unmounts or playback stops
        // return () => clearInterval(interval);
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        // Set up an interval to save current time every 30 seconds
        intervalRef.current = setInterval(() => {
            console.log('Interval triggered. Saving time:', currentTimeRef.current);
            saveCurrentTime(currentTimeRef.current, 'fromHandlePlay');
        }, 30000);

    }

    const handleReady = () => {
        // if (playerRef.current) {
        //     playerRef.current.seekTo(currentTimeRef.current);
        // } else {
        //     console.error('Player reference is null');
        // }
    };

    console.log(currentTimeRef, 'currentTimeRef')

    const handleDuration = (duration) => {
        setTotalDuration(duration);
        console.log(duration, "duration")
    };


    useEffect(() => {
        setLoading(true);

        let body = {
            courseId: id
        }

        axios.post(`${BaseUrl}courses/findOne`, body)

            .then((res) => {

                let response = res.data;

                if (response.statuscode === 200) {
                    console.log(res.data.data[0], 'courseDetails');
                    setCourseName(response.data[0].course_title);
                }
                else {
                    setCourseName('');
                }
            })
            .catch((err) => {
                console.log(err.message)
            })


        let trainingBody = {
            training_module_id: moduleId
        }

        axios.post(`${BaseUrl}training-module/findOne`, trainingBody)
            .then((res) => {
                console.log(res.data, 'hhhh')
                if (res.data.statuscode === 200) {
                    setMaterialName(res.data.data.training_module_name);
                    setTrainingModule(res.data.data.training_module)
                    setType(res.data.data.training_module_type)
                    if (res.data.data.training_module_type == "pdf") {

                        setDocUrl(res.data.data.training_module)
                    }

                } else {
                    setMaterialName('Training Material Not found!')
                }
            })
            .catch((err) => {
                console.log(err.message)
            })

        axios.post(`${BaseUrl}course-parts/findAllModulesByCourseId`, body)
            .then((res) => {

                let response = res.data;
                console.log(response, 'chan')

                if (response.statuscode === 200) {
                    let arr = []
                    let transformed = response.data.map((item) => {
                        if (item.type === "mp4") {
                            let obj = {
                                key: item.key,
                                label: item.label,
                                time: item.time,
                                type: item.type,
                                icon: <PlayCircleOutlinedIcon />
                            }
                            arr.push(obj)
                        }
                        if (item.type === "") {
                            let obj = {
                                key: item.key,
                                label: item.label,
                                time: item.time,
                                type: item.type,
                                icon: <ArticleIcon />
                            }
                            arr.push(obj)
                        }
                        if (item.type === "pdf") {
                            let obj = {
                                key: item.key,
                                label: item.label,
                                time: item.time,
                                type: item.type,
                                icon: <ArticleIcon />
                            }
                            arr.push(obj)
                        }

                        settrainingMenu(arr)
                        console.log(arr, "arrrr")
                    })
                } else {
                    settrainingMenu([]);
                }
            })
            .catch((err) => {
                console.log(err.message)
            })
            .finally(() => {
                setLoading(false);
            })

    }, [moduleId])

    const playerConfig = {
        file: {
            attributes: {
                controlsList: 'nodownload', // Disable the download button
            },
        },
    };

    const handleClick = (e) => {
        console.log(e, 'courseSlider');

        navigate(`/courses/${id}/pages/${e.key}`);

        // const itemfound = items2.find(item => parseInt(e.key) === item.key)
        // console.log(itemfound, "found")
        // if (itemfound) {
        //     setMaterialName(itemfound.label)
        // } else {
        //     setMaterialName("Course not available!")
        // }
    }



    return (
        <div className='Pages-main'>
            <p className='back'>
                <span onClick={(e) => navigate(`/courses`)} style={{ color: '#4096FF', textDecoration: "underline" }}>
                    courses
                </span>
                <span>
                    <ArrowForwardIosIcon className='arrow' />
                </span>
                {courseName && <span onClick={(e) => navigate(`/courses/${id}`)} style={{ color: '#4096FF', textDecoration: "underline" }}>
                    {courseName}
                </span>}
                <span>
                    <ArrowForwardIosIcon className='arrow' />
                </span>
                {MaterialName && <span style={{ color: '#454545', textDecoration: "underline" }}>
                    {MaterialName}
                </span>}
            </p>
            <Row>
                <Col span={4}>
                    <h2>
                        Course Contents
                    </h2>

                    <Sider
                        width={250}
                        style={{
                            background: "#fff",
                        }}
                        className='Slider'
                    >
                        <Menu
                            mode="inline"
                            // defaultSelectedKeys={['1']}
                            defaultOpenKeys={['sub1']}
                            style={{
                                height: '100%',
                                borderRight: 0,
                            }}
                            onSelect={(e) => handleClick(e)}
                            items={trainingMenu}
                        />
                    </Sider>
                </Col>
                <Col offset={2} span={18}>
                    {courseName ?
                        <h1 className='MaterialName' >
                            {courseName} - {MaterialName}
                        </h1>
                        : <></>
                    }

                    {Type === "mp4" ?

                        <div className='videoDiv'>

                            <ReactPlayer
                                ref={playerRef}
                                width={900}
                                height={400}
                                controls
                                config={playerConfig}
                                onDuration={handleDuration}
                                onProgress={handleProgress}
                                url={TrainingModule}
                                onPause={handlePause}
                                onPlay={handlePlay}
                            onReady={handleReady}
                            />
                        </div>
                        :
                        <div className='Doc'>


                            {
                                DocUrl != "" ?

                                    <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}>
                                        <Viewer fileUrl={DocUrl} />
                                    </Worker>
                                    :
                                    <div className='Loader' >
                                        <img src='/Loader/Ellipsis-2.9s-200px.svg'>
                                        </img>
                                    </div>
                            }

                        </div>
                    }
                </Col>
            </Row>
        </div>
    )
}

export default Pages