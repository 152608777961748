import React, { useState, useEffect } from 'react'
import { Col, Row, Input, Button, message, Form, notification } from 'antd'
import './Login.css'
import { json, useNavigate } from 'react-router';
import { BaseUrl } from '../Constants/Constants';
import axios from 'axios';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import { MailOutlined, LockOutlined } from '@ant-design/icons'
import { useStateValue } from '../../Reducer/reducer';
import { actionTypes } from '../../Reducer/StateProvider';
import { startSession } from './sessionService';


const Login = () => {
  const [
    { },
    dispatch,
  ] = useStateValue();
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [EmailStatus, setEmailStatus] = useState('');
  const [pwdStatus, setPwdStatus] = useState('');

  const openNotificationWithIconWarning = (type) => {
    api['warning']({
      message: "Warning!!!",
      description:
        type,
    });
  };

  const handleEmailChange = (value) => {
    setEmail(value);

    // Email validation regex pattern
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(value);

    // Update email status based on validation
    setEmailStatus(isValidEmail ? 'success' : 'error');
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);

    // Password validation regex pattern
    const passwordRegex = /^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
    const isValidPassword = passwordRegex.test(value);

    // Update password status based on validation
    setPwdStatus(isValidPassword ? 'success' : 'error');
    console.log(isValidPassword, "isValidPassword")
  };

  const openNotificationWithIconSuccess = (type) => {
    console.log('getsSuccess')
    api['success']({
      message: "Success!!!",
      description:
        type,
    });
  };

  useEffect(() => {
    localStorage.setItem("IsAuthenticated", "false");
    dispatch({
      type: actionTypes.SET_ISAUTHENTICATED,
      IsAuthenticated: "false"
    })
  }, [])


  const handleLogin = () => {
    let Body = {
      "username": email,
      "password": password,
      "email": email.toLowerCase()
    }
    if (email && password && pwdStatus == "success") {
      axios
        .post(`${BaseUrl}auth/login`, Body)
        .then((res) => {
          console.log(res.data, 'loginResponse')
          if (res.data.status === 204) {
            openNotificationWithIconWarning('Invalid Credentials');
          }
          else {
            openNotificationWithIconSuccess('Logged in Sucessfully')
            localStorage.setItem("Profile", JSON.stringify(res.data));
            localStorage.setItem("companyId", JSON.stringify(res.data.company_id.company_id));
            localStorage.setItem("IsAuthenticated", "true");
            startSession(res.data.user_id);
            dispatch({
              type: actionTypes.SET_ISAUTHENTICATED,
              IsAuthenticated: "true"
            })
            dispatch({
              type: actionTypes.SET_UserID,
              UserID: res.data.user_id
            })
            dispatch({
              type: actionTypes.SET_COMPANYID,
              companyId: res.data.company_id.company_id
            })
            if (res.data.newuser) {
              navigate('/PasswordChange');
            } else {
              navigate('/');
            }
          }
        })
        .catch((err) => {
          // openNotificationWithIconWarning('Invalid Credentials');
          console.log(err, "log")
        })
    }
    else {

      openNotificationWithIconWarning('Please enter valid Credentials');
    }
  }

  const handleForgotPass = () => {
    navigate("/forgotpassword")
  }
  const handleSignup = () => {
    navigate("/signup")
  }

  //google login 
  const [user, setUser] = useState({});
  const [profile, setProfile] = useState([]);
  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log('Login Failed:', error)
  });




  useEffect(() => {
    if (Object.keys(user).length > 0) {
      axios
        .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            Accept: 'application/json'
          }
        })
        .then((res) => {
          console.log(res.data.email, "chan")

          axios
            .get(`${BaseUrl}users/${res.data.email.toLowerCase()}`)
            .then((res) => {
              if (res.status == 200) {
                setProfile(res.data);
                localStorage.setItem("Profile", JSON.stringify(res.data));
                localStorage.setItem("IsAuthenticated", "true");
                startSession(res.data.user_id);
                dispatch({
                  type: actionTypes.SET_ISAUTHENTICATED,
                  IsAuthenticated: "true"
                })
                navigate('/')
              }
              else {
                openNotificationWithIconWarning('User Not Found');
              }
            })
            .catch((err) => {
              console.log(err)
              openNotificationWithIconWarning('User Not Found');
            })
        })
        .catch((err) => console.log(err));
    }
  }, [user]);


  // log out function to log the user out of google and set the profile array to null
  const logOut = () => {
    googleLogout();
    setProfile(null);
  };

  return (

    <div className='LoginImg'>
      {contextHolder}
      <Row className='LoginRow'>
        <Col>
          <div className='Login'>
            <div className='logindiv'>
              <div className='LogoDiv'>
                <img src='/Logo/Logo Svg OG 3.png' className='loginImage' alt='Logo' />
              </div>
              {/* <p className='LoginH1'>
                Login Portal
              </p> */}
          <Input
  onKeyDown={(e) => {
    if (e.key === 'Enter') {
      document.getElementById('passwordInputField').focus();
    }
  }}
  className='loginInput'
  placeholder='Email ID'
  onChange={(e) => {
    if (e && e.target) {
      // Remove spaces while typing
      handleEmailChange(e.target.value.replace(/\s/g, ''));
    }
  }}
  // value={email}  
  status={!email ? EmailStatus : ''}
/>

              <Form.Item className='PasswordForm' hasFeedback validateStatus={pwdStatus}>
                <Input.Password
                  id='passwordInputField'
                  onKeyDown={(e) => {
                    if (e.key == 'Enter') handleLogin();
                  }}
                  className='loginInput'
                  placeholder='Password'
                  type='password'
                  onChange={handlePasswordChange}
                  status={!password ? pwdStatus : ''}
                />
              </Form.Item>
            </div>
            <div className='forgotpassDiv'>
              <Button
                onClick={handleForgotPass}
                className='forgotpassword' size='small' type='link'
              >
                Forgot Password?
              </Button>
            </div>
            <Button
              style={{ marginTop: 30 }}
              onClick={handleLogin}
              className='signUpButton'
            >
              Login
            </Button>
            <div>
              <p className='ortag'>------------ or ------------</p>
              <div className='Btncls'>
                <Button
                  onClick={() => googleLogin()}
                  className='signUpButton'
                >
                  Sign in with Google
                </Button>
                {/* <Button
                  onClick={handleSignup}
                  className='signUpButton'>
                  Signup
                </Button> */}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Login