import { Button, Card, message, Radio, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import './ReviewAnswerModule.css'
import { useNavigate, useParams } from 'react-router'
import { AssesmentUrl } from '../../../../Constants/Constants'
import axios from 'axios'

const ReviewAnswerModule = () => {

  const navigate = useNavigate();

  const { id, assessmentId } = useParams();

  const profileDetails = JSON.parse(localStorage.getItem('Profile'));
  const userId = profileDetails.user_id;

  const [reviewData, setReviewData] = useState([]);

  const formatQuestion = (question) => {
    if (!question) return ''; 

    let formattedQuestion = question.replace(/\\n\s*([a-zA-Z])\./g, '<br/>$1.');

    formattedQuestion = formattedQuestion.replace(/(?:^|\s)([a-z])\./g, (match, p1) => {
        return match.replace(p1, p1.toUpperCase());
    });

    formattedQuestion = formattedQuestion.replace(/\\n(?!\s*[a-zA-Z]\.)/g, '<br/>');

    
    formattedQuestion = formattedQuestion.replace(/\s*<br\/>\s*/g, '<br/>');

    return formattedQuestion;
};

  useEffect(() => {

    let reviewAnswersUrl = `${AssesmentUrl}mcq_review_answers_course`

    let body = {
      course_id: id,
      assessment_id: assessmentId,
      user_id: userId,
      training_id: "string"
    }

    axios.post(reviewAnswersUrl, body)

      .then((res) => {
        console.log(res, 'reviewAnswersRes');

        let response = res.data;

        if (response.status === 200) {
          setReviewData(response.review_answers_details);
        } else {
          setReviewData([]);
        }

      }).catch(err => {
        message.error(err.message);
      })

  }, [])

  const radioJson = [
    {
      questionId: 'mcqid-1',
      question: "What is HIDA Scan ?",
      option: [
        {
          label: 'To Image lungs',
          value: 1,
        },
        {
          label: 'To access brain tumors',
          value: 2,
        },
        {
          label: 'To measure bone density',
          value: 3,
        },
      ],
      userAnswer: 1,
      correctAnswer: 2,
      isCorrect: false
    },
    {
      questionId: 'mcqid-2',
      question: "What is HIDA Scan ?",
      option: [
        {
          label: 'To Image lungs',
          value: 1,
        },
        {
          label: 'To access brain tumors',
          value: 2,
        },
        {
          label: 'To measure bone density',
          value: 3,
        },
      ],
      userAnswer: 1,
      correctAnswer: 1,
      isCorrect: true
    },
    {
      questionId: 'mcqid-3',
      question: "What is HIDA Scan ?",
      option: [
        {
          label: 'To Image lungs',
          value: 1,
        },
        {
          label: 'To access brain tumors',
          value: 2,
        },
        {
          label: 'To measure bone density',
          value: 3,
        },
      ],
      userAnswer: 2,
      correctAnswer: 1,
      isCorrect: false
    },
    {
      questionId: 'mcqid-4',
      question: "What is HIDA Scan ?",
      option: [
        {
          label: 'To Image lungs',
          value: 1,
        },
        {
          label: 'To access brain tumors',
          value: 2,
        },
        {
          label: 'To measure bone density',
          value: 3,
        },
      ],
      userAnswer: 2,
      correctAnswer: 3,
      isCorrect: false
    },
    {
      questionId: 'mcqid-5',
      question: "What is HIDA Scan ?",
      option: [
        {
          label: 'To Image lungs',
          value: 1,
        },
        {
          label: 'To access brain tumors',
          value: 2,
        },
        {
          label: 'To measure bone density',
          value: 3,
        },
      ],
      userAnswer: 1,
      correctAnswer: 1,
      isCorrect: true
    },
  ]


  const handleDashboard = () => {
    navigate(`/`)
  }


  return (
    <div className='reviewModuleMainDiv'>
      <h2 className='reviewAnswerClass'>Review Your Answers</h2>
      {
        reviewData.length != 0 && reviewData.map((item, index) => (
          <>
            <Card key={index} className={item.isCorrect ? 'correctAnswerCard' : 'wrongAnswerCard'}>
              <p className={item.isCorrect ? 'correctAnswerResultType' : 'wrongAnswerResultType'}>{item.isCorrect ? 'Correct Answer' : 'Incorrect Answer'}</p>
              <p dangerouslySetInnerHTML={{ __html: formatQuestion(item.question) }}></p>
              <Radio.Group value={item.userAnswer}>
                <Space direction='vertical' >
                  {
                    item.option.map((opt, index) => {

                      const isUserAnswer = opt.value === item.userAnswer;
                      const isCorrectAnswer = opt.value === item.correctAnswer;
                      console.log(opt.value, 'optValue', item.userAnswer)

                      let className = '';
                      if (!item.isCorrect && isUserAnswer) {
                        className = 'radio-incorrect';
                      } else if (isCorrectAnswer) {
                        className = 'radio-green';
                      }

                      return (
                        <Radio
                          className={className}
                          key={opt.value}
                          value={opt.value}
                        // disabled={isCorrectAnswer}
                        >{opt.label}</Radio>
                      )
                    })
                  }
                </Space>
              </Radio.Group>
            </Card>
          </>
        ))
      }
            <Button className='DashboardButton'
              type='primary' onClick={handleDashboard}
            >
              Go To Dashboard
            </Button>
    </div>
  )
}

export default ReviewAnswerModule