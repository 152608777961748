// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headerDiv{
    background-color: white;
    border-bottom: 1px solid #c2c2c2;
    color: #fff;
    padding: 3px 12px;
    position: fixed;
    width: 100%;
    z-index: 999;
    height: 60px;
    
}
.pageHadingRow h3{
    color: #000000;
    font-weight: 400;
    cursor: pointer;
    font-size: 15px;
    margin-top: 18px;
}
.headerSearch{
    margin-top: 13px;
}
.headingAvatar {
    margin: 12px 0px 0px 8px;
    float: right;
}
.pageHadingRow h3:hover{
    color: #4096ff;
}
.pageHadingRow .active{
    color: #4096ff;
}
.headerLogoImg{
    margin-top: 10px;
    cursor: pointer;
}
.campaign{
    color: #000;
    line-height: 35px;
    font-size: 17.5px;
}
.Email{
    margin: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* margin-top: 18px; */
}
.Username{
    margin-bottom: 0px;
    margin-top: 6px;

}
.Contents{
    cursor: pointer;
}
.Notification{
    color: #000000 !important;
    font-size: 24px;
    float: right;
    margin-top: 17px;
    cursor: pointer;

}`, "",{"version":3,"sources":["webpack://./src/Components/Header/Header.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,gCAAgC;IAChC,WAAW;IACX,iBAAiB;IACjB,eAAe;IACf,WAAW;IACX,YAAY;IACZ,YAAY;;AAEhB;AACA;IACI,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,wBAAwB;IACxB,YAAY;AAChB;AACA;IACI,cAAc;AAClB;AACA;IACI,cAAc;AAClB;AACA;IACI,gBAAgB;IAChB,eAAe;AACnB;AACA;IACI,WAAW;IACX,iBAAiB;IACjB,iBAAiB;AACrB;AACA;IACI,WAAW;IACX,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;AAC1B;AACA;IACI,kBAAkB;IAClB,eAAe;;AAEnB;AACA;IACI,eAAe;AACnB;AACA;IACI,yBAAyB;IACzB,eAAe;IACf,YAAY;IACZ,gBAAgB;IAChB,eAAe;;AAEnB","sourcesContent":[".headerDiv{\n    background-color: white;\n    border-bottom: 1px solid #c2c2c2;\n    color: #fff;\n    padding: 3px 12px;\n    position: fixed;\n    width: 100%;\n    z-index: 999;\n    height: 60px;\n    \n}\n.pageHadingRow h3{\n    color: #000000;\n    font-weight: 400;\n    cursor: pointer;\n    font-size: 15px;\n    margin-top: 18px;\n}\n.headerSearch{\n    margin-top: 13px;\n}\n.headingAvatar {\n    margin: 12px 0px 0px 8px;\n    float: right;\n}\n.pageHadingRow h3:hover{\n    color: #4096ff;\n}\n.pageHadingRow .active{\n    color: #4096ff;\n}\n.headerLogoImg{\n    margin-top: 10px;\n    cursor: pointer;\n}\n.campaign{\n    color: #000;\n    line-height: 35px;\n    font-size: 17.5px;\n}\n.Email{\n    margin: 0px;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    /* margin-top: 18px; */\n}\n.Username{\n    margin-bottom: 0px;\n    margin-top: 6px;\n\n}\n.Contents{\n    cursor: pointer;\n}\n.Notification{\n    color: #000000 !important;\n    font-size: 24px;\n    float: right;\n    margin-top: 17px;\n    cursor: pointer;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
