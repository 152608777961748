// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.courseModuleMainDiv {
    margin: 80px 24px;
}
.courseHeading{
    font-weight: 500;
    font-size: 17px;
    text-transform: capitalize;
}
.sourceIcon svg{
    font-size: 25px;
    margin-top: 10px;
    color: yellowgreen;
}
.sourceStatus svg{
    font-size: 25px;
    margin-top: 10px;
    color: #8dce08;
}
.courseSourceTypes{
    border: 1px solid #c6c5c5;
    border-radius: 8px;
    padding: 6px;
    margin-bottom: 10px;
    cursor: pointer;
}
.accordionHeading{
    margin: 8px 0px;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    color: #474bdd;
}
.courseModuleMainDiv .MuiAccordionSummary-content{
    margin: 15px 0px;
}
.assessmentHeading{
    font-weight: 500;
    font-size: 17px;
    text-transform: capitalize;
    margin-top: 5%;
}
.assessmentIcon svg{
    font-size: 25px;
    margin-top: 10px;
    color: #3274cd;
}
.scoreStatus p{
    color: #969696;
}
.sourceStatuss{
    margin-top: 15px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Courses/CourseModule/CourseModule.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,0BAA0B;AAC9B;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB;AACA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;IACZ,mBAAmB;IACnB,eAAe;AACnB;AACA;IACI,eAAe;IACf,eAAe;IACf,gBAAgB;IAChB,0BAA0B;IAC1B,cAAc;AAClB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,0BAA0B;IAC1B,cAAc;AAClB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB;AACA;IACI,cAAc;AAClB;AACA;IACI,gBAAgB;AACpB","sourcesContent":[".courseModuleMainDiv {\n    margin: 80px 24px;\n}\n.courseHeading{\n    font-weight: 500;\n    font-size: 17px;\n    text-transform: capitalize;\n}\n.sourceIcon svg{\n    font-size: 25px;\n    margin-top: 10px;\n    color: yellowgreen;\n}\n.sourceStatus svg{\n    font-size: 25px;\n    margin-top: 10px;\n    color: #8dce08;\n}\n.courseSourceTypes{\n    border: 1px solid #c6c5c5;\n    border-radius: 8px;\n    padding: 6px;\n    margin-bottom: 10px;\n    cursor: pointer;\n}\n.accordionHeading{\n    margin: 8px 0px;\n    font-size: 14px;\n    font-weight: 400;\n    text-transform: capitalize;\n    color: #474bdd;\n}\n.courseModuleMainDiv .MuiAccordionSummary-content{\n    margin: 15px 0px;\n}\n.assessmentHeading{\n    font-weight: 500;\n    font-size: 17px;\n    text-transform: capitalize;\n    margin-top: 5%;\n}\n.assessmentIcon svg{\n    font-size: 25px;\n    margin-top: 10px;\n    color: #3274cd;\n}\n.scoreStatus p{\n    color: #969696;\n}\n.sourceStatuss{\n    margin-top: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
