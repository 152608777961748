import React from 'react'

const SubComponent = () => {
  return (
    <>
    {/* <div className='main-container' >
        Content
    </div> */}
    </>
  )
}

export default SubComponent