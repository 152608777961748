// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Settings-main{
    margin-left: 24px ;
    margin-top: 80px;
}
.Settings-card{
    margin-top: 20px;
    margin-left: 200px;
    cursor: pointer;
}

.cancel-button {
    background-color: #f5f5f5;
    color: #000; 
    border: 1px solid #d9d9d9; 
    border-radius: 4px; /* Slightly rounded corners */
    padding: 0 15px; /* Padding for better appearance */
    height: 32px; /* Match height with other buttons */
    margin-left: 8px; /* Space between Save and Cancel button */
}`, "",{"version":3,"sources":["webpack://./src/Components/Settings/Settings.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;AACA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,yBAAyB;IACzB,kBAAkB,EAAE,6BAA6B;IACjD,eAAe,EAAE,kCAAkC;IACnD,YAAY,EAAE,oCAAoC;IAClD,gBAAgB,EAAE,yCAAyC;AAC/D","sourcesContent":[".Settings-main{\n    margin-left: 24px ;\n    margin-top: 80px;\n}\n.Settings-card{\n    margin-top: 20px;\n    margin-left: 200px;\n    cursor: pointer;\n}\n\n.cancel-button {\n    background-color: #f5f5f5;\n    color: #000; \n    border: 1px solid #d9d9d9; \n    border-radius: 4px; /* Slightly rounded corners */\n    padding: 0 15px; /* Padding for better appearance */\n    height: 32px; /* Match height with other buttons */\n    margin-left: 8px; /* Space between Save and Cancel button */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
