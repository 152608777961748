import axios from 'axios';
import { BaseUrl } from '../Constants/Constants';

export const startSession = async (userId) => {
  try {
    await axios.post(`${BaseUrl}user-login-time/start-session/${userId}`);
  } catch (error) {
    console.error('Error starting session:', error);
  }
};

export const updateActive = async (userId) => {
  try {
    await axios.post(`${BaseUrl}user-login-time/update-active/${userId}`);
  } catch (error) {
    console.error('Error updating active status:', error);
  }
};

export const endSession = async (userId) => {
  try {
    await axios.post(`${BaseUrl}user-login-time/end-session/${userId}`);
  } catch (error) {
    console.error('Error ending session:', error);
  }
};

export const getTotalTime = async (userId) => {
  try {
    const response = await axios.get(`${BaseUrl}user-login-time/todays-time/${userId}`);
    return response.data.totalTime;
  } catch (error) {
    console.error('Error getting total time:', error);
    return null;
  }
};

export const getAllCompletedCourses = async (userId) => {
  try {
    const response = await axios.get(`${BaseUrl}course-tracker/total-completed-courses/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Error getting completed courses:', error);
    return null;
  }
};

export const getAllInprogressCourses = async (userId) => {
  try {
    const response = await axios.get(`${BaseUrl}course-tracker/total-InProgress-courses/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Error getting inProgress courses:', error);
    return null;
  }
};
export const getAllInCompletesCourses = async (userId) => {
  try {
    const response = await axios.get(`${BaseUrl}course-tracker/total-Incomplete-courses/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Error getting Incomplete courses:', error);
    return null;
  }
};

