import React, { useState, useEffect } from 'react'
import { Button } from 'antd'
import { useNavigate,useParams } from 'react-router-dom'
import axios from 'axios'
import { AssesmentUrl,BaseUrl } from '../../../Constants/Constants'
import { useStateValue } from '../../../../Reducer/reducer'
import ReviewAnswerModuleTraining from './ReviewAnswerModule/ReviewAnswerModuleTraining'

const TrainingMcqResult = () => {

  const navigate = useNavigate()
  const { id, assessmentId } = useParams();
  const [
    { UserID  }
] = useStateValue();
const [Score, setScore] = useState('')
const [Data, setData] = useState('')

useEffect(() => {
  let body = {
    assessment_id: assessmentId
  }
  axios
      .post(`${BaseUrl}assesment/Assesment_details`,body)
      .then((res) => {
          setData(res.data.data)
          console.log(res.data.data ,"len")
      })
      .catch((err) => {

      })
      .finally((res) => {

      })
}, [])

  useEffect(() => {
    let body = {
      user_id: UserID,
      training_id: id,
      assessment_id : assessmentId
    }
    axios
      .post(`${AssesmentUrl}assessment_score_training`, body)
      .then((res) => {
        console.log(res.data.scores[0], 'res');
        const score = res.data.scores[0]?.score; 
        if (score !== undefined) {
          setScore(score);
          console.log(score, 'score');
        }
      })
      .catch((err) => {
        console.log(err)
      })

  }, [])

  const handleDashboard = () => {
    navigate(`/`)
  }
  return (
    <div>
    <div className='mcq-res-MainDiv'>
      <div>

        <h1 className='Thanks'>
          Thank You
        </h1>
        <p className='completed'>
          Test Completed sucessfully
        </p>

        <h2 className='completed' >
          Your Score : {Score}/{Data.Number_of_questions}
        </h2>
        <Button className='BackToDashboard'
          type='primary' onClick={handleDashboard}
        >
          Dashboard
        </Button>

      </div>

    </div>
    <ReviewAnswerModuleTraining />
    </div>
  )
}

export default TrainingMcqResult