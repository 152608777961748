import React, { useState, useEffect } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router'
import './TrainingAssessment.css'
import { Button, Card, Col, Row, notification } from 'antd'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import axios from 'axios';
import { AssesmentUrl,BaseUrl, getImageQuestion, getManualQuestion } from '../../Constants/Constants';
import { useStateValue } from '../../../Reducer/reducer';
import { actionTypes } from '../../../Reducer/StateProvider';

const TrainingAssessment = () => {

    const [api, contextHolder] = notification.useNotification();

    const openNotificationWithIconError = (type) => {
        api['error']({
          message: "Oops!!!",
          description:
            type,
        });
      };

    const [courseName, setCourseName] = useState("");
    const [mcqid, setmcqId]= useState("");
    const [
        { UserID },
        dispatch
    ] = useStateValue();
    const { id, courseId, assessmentId } = useParams();

    const profileDetails  = JSON.parse(localStorage.getItem('Profile'));

    console.log(profileDetails,'profileDetails')

    const userId = profileDetails.user_id;


    console.log(id, )
    const navigate = useNavigate();
    const [Data, setData] = useState('')
    const [Loading, setLoading] = useState(false)
    const [Questions, setQuestions] = useState({})
    let url = window.location.href;
  let splittedUrl = url.split('/')[6];
  const stringWithSpace = splittedUrl.replace(/-/g, ' ');

  console.log(Data, 'splittedUrl')

    const course = [
        {
            id: "720399f5-8b92-4ca7-8b1f-4dd5410b049b",
            name: "Radiology Partner Course",
        },
        {
            id: "0002",
            name: "Medical Terminology and Anatomy",
        },
        {
            id: "0003",
            name: "Nursing Fundamentals",
        },
        {
            id: "0004",
            name: "Healthcare Informatics",
        },
        {
            id: "0005",
            name: "Epidemiology and Public Health",
        },
        {
            id: "0006",
            name: "Health Policy and Administration",
        },
        {
            id: "0007",
            name: "Patient Care Technician Training",
        },
        {
            id: "0008",
            name: "Inroduction to radiology",
        },
        {
            id: "0009",
            name: "Fundamentals of radiology",
        },
        {
            id: "0010",
            name: "Human Anatomy",
        },
    ];

    // useEffect(() => {
    //     const foundItem = course.find(item => item.id === id);
    //     if (foundItem) {
    //         setCourseName(foundItem.name)
    //     } else {
    //         setCourseName("Course not available!")
    //     }
    // }, [])

    useEffect(() => {

        let body = {
            training_id: id
        }

        axios.post(`${BaseUrl}trainings/findOneTraining`, body)

        .then((res) => {

            let response = res.data;

            if (response.statuscode === 200) {
                console.log(res.data.data, 'courseDetails');
                setCourseName(response.data.training_title);
            }
            else {
                setCourseName('');
            }
        })
        .catch((err) => {
            console.log(err.message)
        })
    }, [])

    useEffect(() => {
        let body = {
            assessment_id : assessmentId
        }
        axios
            .post(`${BaseUrl}assesment/Assesment_details`,body)
            .then((res) => {
                setData(res.data.data)
                console.log(res.data.data ,"Assesment_details")
            })
            .catch((err) => {
                openNotificationWithIconError(err.message);
            })
            .finally((res) => {

            })
    }, [])


   

    const handleTest = () => {

        if( Data.assessment_type_id === 1 ) {
        setLoading(true)
        const cleanedString = courseName.replace(/\s+/g, ' ');  
        let modUrl = cleanedString.toLowerCase().split(' ');
        let url = modUrl.join('-');
        let structuredUrl = url.split('/').join('-');
        let Reqbody = {
            course_id: courseId,
            user_id: userId,
            assessment_id : assessmentId
        }
        console.log('hitnbkajsdf',Reqbody)
        axios
            .post(`${AssesmentUrl}get_questions`, Reqbody)
            .then((res) => {
                console.log(res.data.generated_mcq, "questions")
                setQuestions(res.data.generated_mcq)
                setLoading(false)
                dispatch({
                    type: actionTypes.SET_Questions,
                    Questions: res.data.generated_mcq
                  })
                  const questionIds = res.data.generated_mcq.map(question => question.question_id);

                  console.log(questionIds,"quest")
                navigate(`/training/${id}/${courseId}/assessment/${assessmentId}/mcq`)

        
            })
            .catch((err) => {
                console.log(err, 'getQuestionErr');
                openNotificationWithIconError('Error Getting Questions!');
                setLoading(false);
            })
        } else if( Data.assessment_type_id === 2 ) {

            let body = {
                course_id : courseId,
                user_id : userId,
                assessment_id : assessmentId,
                no_of_questions : ""
            }

            axios.post(`${AssesmentUrl}get_image_questions`, body )
            .then(( res ) => {
                console.log(res, 'getImageQuestions');
                setLoading(false)
                dispatch({
                    type: actionTypes.SET_Questions,
                    Questions: res.data
                  })
                //   const questionIds = res.data.questions.map(question => question.question_id);

                //   console.log(questionIds,"quest")
                navigate(`/training/${id}/${courseId}/assessment/${assessmentId}/image`)
            })
            .catch(( err ) => {
                console.log(err, 'get_imgage_questionsError');
                openNotificationWithIconError('Error Getting Questions!');
                setLoading(false);
            })
        }
        else if( Data.assessment_type_id === 3 ){
            let body = {
                course_id : courseId,
                user_id : userId,
                assessment_id : assessmentId,
                no_of_questions : ""
            }

            axios.post(`${AssesmentUrl}manual_get_question`, body )
            .then(( res ) => {
                console.log(res, 'getImageQuestions');
                if( res.data.status === 200 ){

                    setLoading(false)
                    dispatch({
                        type: actionTypes.SET_Questions,
                        Questions: res.data.questions
                      })
                    navigate(`/training/${id}/${courseId}/assessment/${assessmentId}/manual`);
                }
                 else{
                    openNotificationWithIconError('Error Getting Questions!');
                 }
            })
            .catch(( err ) => {
                console.log(err, 'get_manual_questionsError');
                openNotificationWithIconError('Error Getting Questions!');
                setLoading(false);
            })
        }
    }

    return (
        <div className='assessmentMainDiv'>
            {
                contextHolder
            }
            <p className='back'>
                <span onClick={(e) => navigate(`/training`)} style={{ color: '#4096FF', textDecoration: "underline" }}>
                    Trainings
                </span>
                <span>
                    <ArrowForwardIosIcon className='arrow' />
                </span>
                {courseName && <span onClick={(e) => navigate(`/training/${id}/${courseId}`)} style={{ color: '#4096FF', textDecoration: "underline" }}>
                    {courseName}
                </span>}
                <span>
                    <ArrowForwardIosIcon className='arrow' />
                </span>
                <span style={{ color: '#454545', textDecoration: "" }}>
                {Data.Assessment_name}
                </span>

            </p>
            <div>
                <h1 className='assessmentCourseHeading'>{Data.Assessment_name}</h1>
            </div>
            <div>
                <Row>
                    <Col span={12}>

                        <Card className='assessmentCard'>
                            <Row className='assessmentCardDiv'>
                                <Col offset={1} span={7}>
                                    <p>Type</p>
                                </Col>
                                <Col span={16}>
                                    {/* <p>:   {splittedUrl}</p> */}
                                    <p style={{ textTransform : 'capitalize' }}>: { Data.assessment_type === 'mcq' ? Data.assessment_type.toUpperCase() : Data.assessment_type }</p>
                                </Col>
                            </Row>
                            <Row className='assessmentCardDiv'>
                                <Col offset={1} span={7}>
                                    <p>Attempts</p>
                                </Col>
                                <Col span={16}>
                                    <p>:   1/1</p>
                                </Col>
                            </Row>
                            <Row className='assessmentCardDiv'>
                                <Col offset={1} span={7}>
                                    <p>Time</p>
                                </Col>
                                <Col span={16}>
                                    <p>:   {Data.Assesment_Duration}m</p>
                                </Col>
                            </Row>
                            <Row className='assessmentCardDiv'>
                                <Col offset={1} span={7}>
                                    <p>Questions</p>
                                </Col>
                                <Col span={16}>
                                    <p>:  {Data.Number_of_questions}</p>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col span={11} offset={1}>
                        <div>
                            <img style={{ marginLeft: '30%' }} src='/icons/exam.png' alt='' width={300} height={300} />
                        </div>
                        <Button loading={Loading} disabled={Loading} onClick={handleTest} className='startTestButton'>Start Test</Button>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default TrainingAssessment