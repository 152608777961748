// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.questionImageClass{
    width: auto;
    margin: 20px 10px;
    max-width: 818px;
}
.answerImageQuestionInput{
    border: none;
    border-bottom: 1px solid #000;
    border-radius: 8px 8px 0px 0px;
    margin-bottom: 15px;
    height: 50px;
    background: aliceblue;
}
.answerImageQuestionInput:focus{
    box-shadow: none !important;
}`, "",{"version":3,"sources":["webpack://./src/Components/Training/TrainingAssessment/ImageAssessment/ImageAssessment.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;IACjB,gBAAgB;AACpB;AACA;IACI,YAAY;IACZ,6BAA6B;IAC7B,8BAA8B;IAC9B,mBAAmB;IACnB,YAAY;IACZ,qBAAqB;AACzB;AACA;IACI,2BAA2B;AAC/B","sourcesContent":[".questionImageClass{\n    width: auto;\n    margin: 20px 10px;\n    max-width: 818px;\n}\n.answerImageQuestionInput{\n    border: none;\n    border-bottom: 1px solid #000;\n    border-radius: 8px 8px 0px 0px;\n    margin-bottom: 15px;\n    height: 50px;\n    background: aliceblue;\n}\n.answerImageQuestionInput:focus{\n    box-shadow: none !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
