// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.manageBotCardCol{   
     margin: 0% 0px 0px 5px;
}
.manageBotCardCol .ant-card-meta-title{
    text-align: center;
    letter-spacing: 0.5px;
}
.manageBotCardCol .ant-card-body{
    padding: 14px;
}
.Course-card{
    padding: 20px 20px 20px 44px;
}
.Course-heading{
    margin-left: 24px;
    font-weight: 500;
    margin-top: 80px;
}
.cards:hover{
    transition: transform 0.3s ease;
    transform: scale(1.1);
}
.arrow{
    margin-bottom: -4px;
    width:15px !important;
    height:15px !important;
    color: #454545;
}
.SearchInput{
    width: 200px;
    float: right;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Courses/Courses.css"],"names":[],"mappings":"AAAA;KACK,sBAAsB;AAC3B;AACA;IACI,kBAAkB;IAClB,qBAAqB;AACzB;AACA;IACI,aAAa;AACjB;AACA;IACI,4BAA4B;AAChC;AACA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,gBAAgB;AACpB;AACA;IACI,+BAA+B;IAC/B,qBAAqB;AACzB;AACA;IACI,mBAAmB;IACnB,qBAAqB;IACrB,sBAAsB;IACtB,cAAc;AAClB;AACA;IACI,YAAY;IACZ,YAAY;AAChB","sourcesContent":[".manageBotCardCol{   \n     margin: 0% 0px 0px 5px;\n}\n.manageBotCardCol .ant-card-meta-title{\n    text-align: center;\n    letter-spacing: 0.5px;\n}\n.manageBotCardCol .ant-card-body{\n    padding: 14px;\n}\n.Course-card{\n    padding: 20px 20px 20px 44px;\n}\n.Course-heading{\n    margin-left: 24px;\n    font-weight: 500;\n    margin-top: 80px;\n}\n.cards:hover{\n    transition: transform 0.3s ease;\n    transform: scale(1.1);\n}\n.arrow{\n    margin-bottom: -4px;\n    width:15px !important;\n    height:15px !important;\n    color: #454545;\n}\n.SearchInput{\n    width: 200px;\n    float: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
