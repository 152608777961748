// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.forgotdiv1{
    border: 2px solid #00000017 !important;
    padding: 15px !important;
    border-radius: 5px !important;
    margin-top: 9rem !important;
    -webkit-backdrop-filter: blur(20px) !important;
            backdrop-filter: blur(20px) !important;
    opacity: 1.9 !important;
    align-items: center !important;
    height: 80% !important;
    width: 100% !important;
 
}
.LogoDivchange{
    display: flex;
    justify-content: center !important;
}

.passInput{
    margin-top: 10px !important;
    background: #FFFFFF;
    border: 1px solid #CDCDCD;
    border-radius: 5px;
    height: 50px;
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/Components/PasswordChange/PasswordChange.css"],"names":[],"mappings":";AACA;IACI,sCAAsC;IACtC,wBAAwB;IACxB,6BAA6B;IAC7B,2BAA2B;IAC3B,8CAAsC;YAAtC,sCAAsC;IACtC,uBAAuB;IACvB,8BAA8B;IAC9B,sBAAsB;IACtB,sBAAsB;;AAE1B;AACA;IACI,aAAa;IACb,kCAAkC;AACtC;;AAEA;IACI,2BAA2B;IAC3B,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;IACZ,WAAW;AACf","sourcesContent":["\n.forgotdiv1{\n    border: 2px solid #00000017 !important;\n    padding: 15px !important;\n    border-radius: 5px !important;\n    margin-top: 9rem !important;\n    backdrop-filter: blur(20px) !important;\n    opacity: 1.9 !important;\n    align-items: center !important;\n    height: 80% !important;\n    width: 100% !important;\n \n}\n.LogoDivchange{\n    display: flex;\n    justify-content: center !important;\n}\n\n.passInput{\n    margin-top: 10px !important;\n    background: #FFFFFF;\n    border: 1px solid #CDCDCD;\n    border-radius: 5px;\n    height: 50px;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
