// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Pages-main{
    margin-left: 24px ;
    margin-top: 80px;
}
.Doc{
    max-height: 70vh;
    overflow-y: scroll;
}
.player{
    padding: 0px !important;
}
.videoDiv{
    display: flex;
    justify-content: center;
}
.Slider{
    max-height: 90vh;
    overflow-y: scroll;
}
.Slider::-webkit-scrollbar{
    width: 5px;
  }
.Slider::-webkit-scrollbar-thumb{
    background-color: #7c7c7c;
    border-radius: 5px;
}
.MaterialName{
    margin-top: 15px;
    text-transform: capitalize;
    margin-bottom: 30px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Pages/Pages.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;AACA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,uBAAuB;AAC3B;AACA;IACI,aAAa;IACb,uBAAuB;AAC3B;AACA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,UAAU;EACZ;AACF;IACI,yBAAyB;IACzB,kBAAkB;AACtB;AACA;IACI,gBAAgB;IAChB,0BAA0B;IAC1B,mBAAmB;AACvB","sourcesContent":[".Pages-main{\n    margin-left: 24px ;\n    margin-top: 80px;\n}\n.Doc{\n    max-height: 70vh;\n    overflow-y: scroll;\n}\n.player{\n    padding: 0px !important;\n}\n.videoDiv{\n    display: flex;\n    justify-content: center;\n}\n.Slider{\n    max-height: 90vh;\n    overflow-y: scroll;\n}\n.Slider::-webkit-scrollbar{\n    width: 5px;\n  }\n.Slider::-webkit-scrollbar-thumb{\n    background-color: #7c7c7c;\n    border-radius: 5px;\n}\n.MaterialName{\n    margin-top: 15px;\n    text-transform: capitalize;\n    margin-bottom: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
