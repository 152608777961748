// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.assessmentMainDiv{
    margin: 80px 24px;
}
.assessmentCourseHeading{
    font-weight: 500;
    font-size: 23px;
    text-transform: capitalize;
}
.assessmentCard p{
    font-size: 20px;
}
.assessmentCard span{
    width: 300px;
}
.assessmentCardDiv{
    display: flex;
}
.assessmentCard{
    border: 1px solid #dddddd;
}
.startTestButton{
    width: 235px;
    height: 40px;
    margin: 10% 0 0 33%;
    background: #4096ff;
    border: 1px solid #4096ff;;
    color: white;
}
.startTestButton:hover{
    width: 235px;
    height: 40px;
    margin: 10% 0 0 33%;
    background: #d5e8ff;
    border: 1px solid #4096ff;
    color: #4096ff;
}`, "",{"version":3,"sources":["webpack://./src/Components/Courses/Assessment/Assessment.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,0BAA0B;AAC9B;AACA;IACI,eAAe;AACnB;AACA;IACI,YAAY;AAChB;AACA;IACI,aAAa;AACjB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,mBAAmB;IACnB,yBAAyB;IACzB,YAAY;AAChB;AACA;IACI,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,mBAAmB;IACnB,yBAAyB;IACzB,cAAc;AAClB","sourcesContent":[".assessmentMainDiv{\n    margin: 80px 24px;\n}\n.assessmentCourseHeading{\n    font-weight: 500;\n    font-size: 23px;\n    text-transform: capitalize;\n}\n.assessmentCard p{\n    font-size: 20px;\n}\n.assessmentCard span{\n    width: 300px;\n}\n.assessmentCardDiv{\n    display: flex;\n}\n.assessmentCard{\n    border: 1px solid #dddddd;\n}\n.startTestButton{\n    width: 235px;\n    height: 40px;\n    margin: 10% 0 0 33%;\n    background: #4096ff;\n    border: 1px solid #4096ff;;\n    color: white;\n}\n.startTestButton:hover{\n    width: 235px;\n    height: 40px;\n    margin: 10% 0 0 33%;\n    background: #d5e8ff;\n    border: 1px solid #4096ff;\n    color: #4096ff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
