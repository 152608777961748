import { Button, Col, Input, notification, Row, Card, Modal, Typography } from 'antd';
import React, { useState, useEffect } from 'react'
import { useStateValue } from '../../../../Reducer/reducer';
import './ManualAssessment.css'
import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router';
import axios from 'axios';
import { AssesmentUrl, BaseUrl, getImageQuestion } from '../../../Constants/Constants';


const { Text } = Typography;
const ImageAssessment = () => {

    const [api, contextHolder] = notification.useNotification();
    const navigate = useNavigate();


    const { id, assessmentId } = useParams();

    const profileDetails  = JSON.parse(localStorage.getItem('Profile'));

    const userId = profileDetails.user_id;

    console.log(profileDetails, 'profileDetails')

    const [timer, setTimer] = useState(1200);


    const openNotificationWithIconError = (type) => {
        api['error']({
            message: "Oops!!!",
            description:
                type,
        });
    };

    const openNotificationWithIconWarning = (type) => {
        api['error']({
            message: "Oops!!!",
            description:
                type,
        });
    };

    const [
        { UserID, Questions },
        dispatch
    ] = useStateValue();

    const openAlert = (type) => {
        Swal.fire({
            title: 'Alert!!!',
            text: type,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                // Perform your action here
                navigate(`/courses`)
            }
        });
    };




    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                console.log("switched")
                openAlert('Switching Tabs leads to end assesment');
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);


    console.log(Questions, 'Questions')

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [answers, setAnswers] = useState(Array(Questions.length).fill(''));
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [resultModal, setResultModal] = useState(false);


    const handleInputChange = (event) => {
        const updatedAnswers = [...answers];
        updatedAnswers[currentQuestionIndex] = event.target.value;
        setAnswers(updatedAnswers);
    };

    const history = useNavigate();
    const { coursename } = useParams();

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    };

    useEffect(() => {
        let body = {
            assessment_id: coursename
        }

        axios
            .post(`${BaseUrl}assesment/Assesment_details`,body)
            .then((res) => {

                let duration = JSON.parse(res.data.data.Assesment_Duration);

                setTimer(duration*60)
                // setData(JSON.parse(res.data.data.Assesment_Duration))
                console.log(typeof JSON.parse(res.data.data.Assesment_Duration) ,"len")
            })
            .catch((err) => {

            })
            .finally((res) => {

            })


    }, [])

    useEffect(() => {
        const countdown = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer === 0) {
                    clearInterval(countdown);
                    // Redirect to the result page or handle quiz completion
                    // history(`/courses/${id}/assessment/${structuredUrl}/mcq/result`);
                }
                return prevTimer - 1;
            });
        }, 1000);

        return () => clearInterval(countdown);
    }, [history]);

    const questionTitle = (
        <div className='QuestionHeader'>
            <p>
                Question {currentQuestion + 1}
            </p>
            <Text className='Ques-txt'>Time Remaining: {formatTime(timer)}</Text>
            {/* <BookmarkBorderIcon 
                    className='Bookmark'
                                    onClick={()=> HandleBookmark(questions[currentQuestion]?.id)}
                                    /> */}
        </div>
    )

    console.log(answers, 'listOfAnswers')

    const handleNextQuestion = () => {
        if (answers[currentQuestionIndex].trim() === '') {
            openNotificationWithIconWarning('Please enter your answer before proceeding.');
            return;
        }
        // Save the current answer and move to the next question
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setCurrentQuestion((prevQuestion) => prevQuestion + 1);
    };
    const handleFinish = () => {

        if (answers[currentQuestionIndex].trim() === '') {
            openNotificationWithIconWarning('Please enter your answer before proceeding.');
            return;
        }
        // Create an array of objects containing questions and answers
        const updatedAnswers = Questions.map((q, index) => ({
            question: q.question,
            answer: answers[index]
        }));

        let body = {
            course_id : id,
            user_id : userId,
            user_response : updatedAnswers,
            assessment_id : assessmentId
        }

        axios.post(`${AssesmentUrl}manual_user_response`, body)

        .then(( res ) => {
            console.log(res, 'img_user_response');

            if( res.data.status === 200 ) {
                setResultModal(true);
            } else{
                openNotificationWithIconError('Error !!!')
            }

        })
        .catch(( err ) => {
            console.log(err, 'img_user_response_Error');
            openNotificationWithIconError('Error posting questions!');
            navigate(`/courses`)
        })


        console.log(updatedAnswers, 'updatedAnswers');
    };

    const questionChart = Questions.map((_, index) => index + 1);

    const rowsOfNumbers = [];
    for (let i = 0; i < questionChart.length; i += 5) {
        rowsOfNumbers.push(questionChart.slice(i, i + 5));
    }

    return (
        <div className='mcqMainDiv'>
            {
                contextHolder
            }
            <Modal className='' open={resultModal} footer={null} onCancel={() => navigate(`/courses`)} >
                <h1 style={{ textAlign: 'center', color: 'red' }}>Congratulations!!!</h1>
                <p style={{ textAlign: 'center' }}>You have completed your assessment</p>
                <h2 style={{ textAlign: 'center', color: 'red' }} >The result will be sent to you shortly</h2>
                {/* <h3 style={{ textAlign: 'center' }}>7/{Questions.length}</h3> */}
                <Button style={{ marginLeft: '37%' }} type='primary' onClick={() => navigate(`/courses`)}>Go to Courses</Button>
            </Modal>
            <Row>
                <Col span={16}>
                    <Card className='questionCard' title={questionTitle} style={{ width: '100%' }}>
                        <h2>Question {currentQuestionIndex + 1}</h2>
                        <p>{Questions[currentQuestionIndex].question}</p>
                        {/* <img className='questionImageClass' src={Questions[currentQuestionIndex].img_dir} height={320} /> */}
                        <Input
                            className='answerImageQuestionInput'
                            placeholder='Enter your answer'
                            type="text"
                            value={answers[currentQuestionIndex]}
                            onChange={handleInputChange}
                        />
                        {
                            currentQuestionIndex === Questions.length - 1 ?
                                <Button
                                    type='primary'
                                    onClick={handleFinish}
                                >Finish</Button>
                                :
                                <Button onClick={handleNextQuestion}
                                    className='image'
                                    type='primary'>
                                    Next Question
                                </Button>
                        }
                    </Card>
                </Col>
                <Col span={7} offset={1}>
                    <Card style={{ width: '100%' }}>
                        <div>
                            <h4 style={{ marginTop: 0 }}>Question Chart</h4>
                            {rowsOfNumbers.map((row, rowIndex) => (
                                <div key={rowIndex} className='questionChartItemsDiv'>
                                    {row.map((questionId) => (
                                        <div
                                            key={questionId}
                                            // onClick={() => HandleSelected(questionId)}
                                            // style={{ backgroundColor:selectedOption == questionId ? "red" : "orange"}}
                                            className={currentQuestion + 1 === questionId ? `questionChartItemsSelected` : `questionChartItems`}
                                        >
                                            <p>{questionId}</p>
                                        </div>
                                    ))}
                                </div>
                            ))
                            }
                        </div>
                    </Card>
                </Col>
            </Row>

        </div>
    )
}

export default ImageAssessment