// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.videoDiv video{
    border-radius: 7px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;

}`, "",{"version":3,"sources":["webpack://./src/Components/Training/TrainingModulePage/TrainingModulePage.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qDAAqD;;AAEzD","sourcesContent":[".videoDiv video{\n    border-radius: 7px;\n    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
