import React, { useEffect, useState } from "react";
// import "./Courses.css";
import { Col, Row, Card, Pagination, message,Input } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BaseUrl, CommuncationBot } from "../../Constants/Constants";
import { useStateValue } from "../../../Reducer/reducer";

const TrainingModule = () => {
    const { Meta } = Card;
    const { Search } = Input;
    const navigate = useNavigate();
  
    const [
      { UserID },
      dispatch,
    ] = useStateValue();
  
    const [courses, setCourses] = useState([]);
    const [coursesarr, setCoursesarr] = useState([]);
    const [loading, setLoading] = useState(true);
  const [InputVal, setInputVal] = useState('')
  
  
    // console.log(UserID, 'UserID')
  
    useEffect(() => {
  
      if (UserID != undefined || UserID != "") {
  
        setLoading(true);
  
        let body = {
          userId: UserID
        }
  
        axios.post(`${BaseUrl}training-mapping/getAllTrainings`, body)
  
          .then((res) => {
            if (res.data.statuscode === 200) {
              setCourses(res.data.data);
              setCoursesarr(res.data.data)
              setLoading(false)
            }
            else {
              setCourses([]);
              setLoading(false);
            }
          })
          .catch((err) => {
            console.log(err.message);
            setLoading(false);
            setCourses([])
          })
      }
      else {
        message.success('Please login again to continue!')
        navigate('/login')
      }
  
    }, [])
  
    const handleCommunication = () => {
      const Profile = localStorage.getItem("Profile");
      const ParsedProfile = JSON.parse( Profile);
      window.location.href=`${CommuncationBot}/${ParsedProfile.email}`
      console.log(ParsedProfile.email, 'userProfile')
    }
  
    const handleCard = (e) => {
      // navigate(`/training/${e.training_id}`);
      setLoading(true);
      let body = {
        training_id: e.training_id
    }

    axios.post(`${BaseUrl}trainings/findOneTraining`, body)

        .then((res) => {

            let response = res.data;

            if (response.statuscode === 200) {
              let courseid = res.data.data.course_id.course_id;
                console.log(res.data.data.course_id, 'courseDetails');
                // setCourseName(response.data[0].training_title);
                navigate(`/training/${e.training_id}/${courseid}`);
            } else{
              message.error('Error !!');
            }
        })
        .catch((err) => {
            console.log(err.message);
            message.error(err.message);
        })
    };
  
    const handleChange = (e) => {
      const value = e.target.value;
    console.log(value,"inputval")
      // Check if the input is empty
      if (value.trim() === '') {
        setCoursesarr(courses); 
      } else {
        // If not empty, filter the array based on the search term
        const filteredResults = courses.filter((item) =>
          item.training_title.toLowerCase().includes(value.toLowerCase())
        
      );
        setCoursesarr(filteredResults);
        console.log(filteredResults,"filtered")
      }
    };
    
    return (
  
      <div className="Courses-main">
  
      
        {/* <Input
        placeholder="Search Course"
        onChange={(e) => handleChange(e)}
        className="SearchInput"
        >
  
        </Input> */}
       
        <Search
        placeholder="Search Course"
        onChange={(e) => handleChange(e)}
        className="SearchInput"
        allowClear
                    />
        <h2 className="Course-heading">Active Training Module</h2>
        {
          loading  ?
            <>
              <div className='Loader' >
                <img src='/Loader/Ellipsis-2.9s-200px.svg'>
                </img>
              </div>
            </>
            :
            <Row className="manageChatbotRow">
             
              {courses.length != 0 ? (
                <>
                  {coursesarr.map((item) => {
                    return (
                      <div className="Course-card">
                        <Col span={6} className="manageBotCardCol">
                          <Card
                            onClick={() => handleCard(item)}
                            hoverable
                            className="cards"
                            style={{
                              width: 270,
                              padding: 2,
                            }}
                            cover={
                              <img
                                alt="Bot Image"
                                // src={item.chat_icon == "" ? "icons\CourseIcons.png" : item.chat_icon}
                                src={item.training_img !== null && item.training_img !== "" ? item.training_img : "/icons/CourseIcons.png" }
                                height={180}
                              />
                            }
                          >
                            <Meta
                              title={
                                item.training_title != ""
                                  ? item.training_title
                                  : `Unnamed Course`
                              }
                            // title={'Bot' + " " + (key + 1)}
                            />
                          </Card>
                        </Col>
                      </div>
                    );
                  })}
                </>
              ) : (
                // <>No items</>
                <></>
              )}
            </Row>
        }
      </div>
    );
  };

export default TrainingModule