// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer{
    background-color: #f4f4f5;
    padding: 0 1.5rem;
    color: #fff;
    min-height: 10vh;
    
}`, "",{"version":3,"sources":["webpack://./src/Components/Footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,iBAAiB;IACjB,WAAW;IACX,gBAAgB;;AAEpB","sourcesContent":["footer{\n    background-color: #f4f4f5;\n    padding: 0 1.5rem;\n    color: #fff;\n    min-height: 10vh;\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
