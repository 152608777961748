// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signup{
    border: 1px solid #00000029;
    padding: 15px;
    border-radius: 5px;
    margin-top: 4rem;
}`, "",{"version":3,"sources":["webpack://./src/Components/Signup/SignUp.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,aAAa;IACb,kBAAkB;IAClB,gBAAgB;AACpB","sourcesContent":[".signup{\n    border: 1px solid #00000029;\n    padding: 15px;\n    border-radius: 5px;\n    margin-top: 4rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
