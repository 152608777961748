import React from 'react'
import Lottie from 'lottie-react';
import { useNavigate,useParams } from 'react-router-dom'
import { Row,Col , Button } from 'antd/es';
import animationData from '../../../../Animation.json';

const McqDescResult = () => {
    const navigate = useNavigate()
    const handleDashboard = () => {
        navigate(`/`)
      }
  return (
    <div>
       <Row justify="center" align="middle">
      <Col style={{ textAlign: 'center' }}>
        <Lottie 
          animationData={animationData}
          loop={true}
          autoplay={true}
          style={{ height: 200, width: 100 }} // Customize the size
        />
        <h2>Your marks will be sent to you via email. </h2>
        {/* <Button 
          type='primary' onClick={handleDashboard}
        >
          Dashboard
        </Button> */}
      </Col>
    </Row>
    </div>
  )
}

export default McqDescResult