import './Mcq.css'
import React, { useState, useEffect } from 'react';
import { Radio, Button, Card, Typography, Col, Row, notification } from 'antd';
import { json, useNavigate, useParams } from 'react-router';
import { useStateValue } from '../../../../Reducer/reducer';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import axios from 'axios';
import { AssesmentUrl, BaseUrl } from '../../../Constants/Constants';
import Swal from 'sweetalert2';
import { actionTypes } from '../../../../Reducer/StateProvider'
import { Input } from 'antd';
const { TextArea } = Input;

const { Text } = Typography;

const McqWithDesc = ( ) => {

    const navigate = useNavigate();

    const [
        { UserID, Questions },
        dispatch
    ] = useStateValue();

    // console.log(Questions,"questions")

    const [questions, setQuestions] = useState(Questions)

 console.log(questions,"questionschan")

    const [api, contextHolder] = notification.useNotification();
    const [mcq_id , setmcq_id]= useState("")


    const [status, setStatus] = useState(Array(questions.length).fill('unanswered')); // "unanswered" or "answered"
    const [viewedStatus, setViewedStatus] = useState(Array(questions.length).fill(false)); // Track viewed questions
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [selectedOption, setSelectedOption] = useState(null);
    const [timer, setTimer] = useState(1200); // 10 minutes in seconds
    const [BookmarkStatus, setBookmarkStatus] = useState(false)
    const history = useNavigate();
    const [Selected, setSelected] = useState('')
    const [UserResponses, setUserResponses] = useState([]);
    const [SelectedQuestionId, setSelectedQuestionId] = useState('')
    const [description, setDescription] = useState('');
   
  const [answers, setAnswers] = useState(Array(10).fill(null));

    const { id } = useParams();
    const { assessmentId } = useParams();

    const cleanedString = assessmentId.replace(/\s+/g, ' ');
    let modUrl = cleanedString.toLowerCase().split(' ');
    let url = modUrl.join('-');
    let structuredUrl = url.split('/').join('-');

    const questionChart = questions.map((_, index) => index + 1);
    // const questionChart = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ];

    const rowsOfNumbers = [];
    for (let i = 0; i < questionChart.length; i += 5) {
        rowsOfNumbers.push(questionChart.slice(i, i + 5));
    }

    // console.log(timer, 'timerDetails')


    useEffect(() => {
        let body = {
            assessment_id: assessmentId
        }

        axios
            .post(`${BaseUrl}assesment/Assesment_details`,body)
            .then((res) => {

                let duration = JSON.parse(res.data.data.Assesment_Duration);

                setTimer(duration*60)
                // setData(JSON.parse(res.data.data.Assesment_Duration))
                console.log(typeof JSON.parse(res.data.data.Assesment_Duration) ,"len")
            })
            .catch((err) => {

            })
            .finally((res) => {

            })


    }, [])

 

    useEffect(() => {
        const countdown = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer === 0) {
                    clearInterval(countdown);
                    // Redirect to the result page or handle quiz completion
                    history(`/courses/${id}/assessment/${structuredUrl}/mcq/result`);
                }
                return prevTimer - 1;
            });
        }, 1000);

        return () => clearInterval(countdown);
    }, [history]);


    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    };
    const handleOptionSelect = (e, questionId) => {
        const selectedOption = e.target.value;
        setSelectedOption(selectedOption);

        setUserResponses((prevResponses) => {
            const existingResponseIndex = prevResponses.findIndex(
                (response) => response.question_id === questionId
            );

            if (existingResponseIndex !== -1) {
                const updatedResponses = [...prevResponses];
                updatedResponses[existingResponseIndex] = { question_id: questionId, answer: selectedOption,  };
                return updatedResponses;
            } else {
                return [...prevResponses, { question_id: questionId, answer: selectedOption }];
            }
        });

        // Update question status to "answered"
        const updatedStatus = [...status];
        updatedStatus[currentQuestion] = 'answered';
        setStatus(updatedStatus);
    };

    // const handleDescriptionChange = (e) => {
    //     setDescription(e.target.value);
    // };

    const handleDescriptionChange = (e) => {
        const newDescription = e.target.value;
        setDescription(newDescription);
        
        const currentQuestionId = questions[currentQuestion]?.question_id;
        const currentQuestionDesc = questions[currentQuestion]?.description;
        setUserResponses((prevResponses) => {
          const existingResponseIndex = prevResponses.findIndex(
            (response) => response.question_id === currentQuestionId
          );
    
          if (existingResponseIndex !== -1) {
            const updatedResponses = [...prevResponses];
            updatedResponses[existingResponseIndex] = { 
              question_id: currentQuestionId, 
              answer: updatedResponses[existingResponseIndex]?.answer || selectedOption,
              user_description: newDescription ,
              description:currentQuestionDesc
            };
            return updatedResponses;
          } else {
            return [...prevResponses, { 
              question_id: currentQuestionId, 
              answer: selectedOption,
              user_description: newDescription ,
              description:currentQuestionDesc
            }];
          }
        });
      };
    console.log( "channnn", UserResponses);
    // console.log(selectedOption, "selected")

    useEffect(() => {
        const currentQuestionId = questions[currentQuestion]?.question_id;
        const existingResponse = UserResponses.find(response => response.question_id === currentQuestionId);
        console.log(existingResponse,"chanexist")
        if (existingResponse) {
          setSelectedOption(existingResponse.answer);
          setDescription(existingResponse.user_description || '');
        } else {
          setSelectedOption(null);
          setDescription('');
        }
      }, [currentQuestion, questions, UserResponses]);


    const openNotificationWithIconWarning = (type) => {
        api['warning']({
            message: "Warning!!!",
            description:
                type,  
        });
    };

    const openAlert = (type) => {
        Swal.fire({
            title: 'Alert!!!',
            text: type,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK',
            allowOutsideClick: false 
        }).then((result) => {
            if (result.isConfirmed) {
                // Perform your action here
               navigate(`/courses/${id}`)
            }
        });
    };


    useEffect(() => {
        if (UserResponses.length > 0) {
          const currentQuestionId = questions[currentQuestion]?.question_id;
          const existingResponse = UserResponses.find(
            (response) => response.question_id === currentQuestionId
          );
    
          if (existingResponse) {
            setSelectedOption(existingResponse.answer);
          } else {
            setSelectedOption(null);
          }
        }
      }, [currentQuestion, questions]);

      const handleNextQuestion = () => {
        
        console.log("Selected Option:", selectedOption);
        console.log("Description:", description);
    
       
        if (selectedOption && !description) {
            console.log("Warning: Description is required.");
            openNotificationWithIconWarning('Description is required.');
            return;
        }
    
       
        handleLeavingQuestion();
    
       
        if (currentQuestion < questions.length - 1) {
            setCurrentQuestion((prev) => prev + 1);
            setSelectedOption(UserResponses[currentQuestion + 1]?.answer || null);
        }
    };
    
    const handlePreviousQuestion = () => {
        if (selectedOption && !description) {
            openNotificationWithIconWarning('Description is required.');
            return;
        }
    
        handleLeavingQuestion();
    
        if (currentQuestion > 0) {
            setCurrentQuestion((prev) => prev - 1);
            setSelectedOption(UserResponses[currentQuestion - 1]?.answer || null);
        }
    };


    const handleFinish = () => {
        const currentQuestionDesc = questions[currentQuestion]?.description;
            setUserResponses((prevResponses) => [...prevResponses, { "question_id": SelectedQuestionId, "answer": selectedOption , "user_description": description ,"description": currentQuestionDesc  }]);

            if (UserResponses.length == questions.length) {

                setAssessmentCompleted(true);
                let body = {
                    course_id: id,
                    user_id: UserID,
                    user_answer: UserResponses,
                    assessment_id : assessmentId
                }
                axios
                    .post(`${AssesmentUrl}mcq_validation`, body)
                    .then((res) => {
                        console.log(res, "response")
                    
                        closeFullscreen();
                        history(`/courses/${id}/assessment/${structuredUrl}/mcq/result`);
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            
        }
        else {
            openNotificationWithIconWarning('Select atleast one option');
        }

    }

    const HandleSlected = (e) => {
        let selectedNum = questions.find((i) => i.id == parseInt(e))
        // questions.push()
        console.log(e, "clicked", selectedNum)
    }
    const handleQuestionClick = (questionIndex) => {
        setCurrentQuestion(questionIndex);
    };


    const HandleSelected = (questionId) => {
        const questionIndex = questionId - 1;
        markQuestionAsViewed(questionIndex);
        setCurrentQuestion(questionIndex);
        setSelectedOption(UserResponses[questionIndex]?.answer || null);
    };

    // Mark a question as viewed
    const markQuestionAsViewed = (questionIndex) => {
        if (status[questionIndex] === 'unanswered' && !viewedStatus[questionIndex]) {
            const updatedViewedStatus = [...viewedStatus];
            updatedViewedStatus[questionIndex] = true;
            setViewedStatus(updatedViewedStatus);
        }
    };

    const handleSelectedQuestion = (questionId) => {
        if (selectedOption && !description) {
            openNotificationWithIconWarning('Description is required.');
            return;
        }
    
        handleLeavingQuestion();
    
        const questionIndex = questionId - 1;
        setCurrentQuestion(questionIndex);
        setSelectedOption(UserResponses[questionIndex]?.answer || null);
    };

    // Handle leaving a question (triggered on navigation)
 const handleLeavingQuestion = () => {
    if (status[currentQuestion] === 'unanswered' && selectedOption === null) {
        const updatedStatus = [...status];
        updatedStatus[currentQuestion] = 'viewed-unanswered';
        setStatus(updatedStatus);
    }
};

    useEffect(() => {
        if (viewedStatus[currentQuestion] && status[currentQuestion] === 'unanswered') {
            const updatedStatus = [...status];
            updatedStatus[currentQuestion] = 'viewed-unanswered';
            setStatus(updatedStatus);
        }
    }, [currentQuestion]);

    const questionTitle = (
        <div className='QuestionHeader'>
            <p>
                Question {currentQuestion + 1}
            </p>
            <Text className='Ques-txt'>Time Remaining: {formatTime(timer)}</Text>
            {/* <BookmarkBorderIcon 
                    className='Bookmark'
                                    onClick={()=> HandleBookmark(questions[currentQuestion]?.id)}
                                    /> */}
        </div>
    )
    const HandleBookmark = (e) => {
        const isBookmarked = e === currentQuestion - 1;
        setBookmarkStatus(isBookmarked)
    }
    useEffect(() => {
        const handleVisibilityChange = () => {
          if (document.visibilityState === 'hidden') {
            console.log("switched")
            // User switched to a different tab or minimized the window
            openAlert('Switching Tabs leads to end assesment');

            // navigate(`/courses/${id}`)



          }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
          // Cleanup the event listener when the component unmounts
          document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
      }, []);
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            // You can perform actions here before the page is unloaded/reloaded
            console.log('Page is about to be reloaded');

            // Optionally, you can show a confirmation message
            const confirmationMessage = 'Are you sure you want to leave?';
            event.returnValue = confirmationMessage;
            return confirmationMessage;
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            // Cleanup the event listener when the component unmounts
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);




    const [isFullScreen, setIsFullScreen] = useState(false);
    const [assessmentCompleted, setAssessmentCompleted] = useState(false);

    const openFullscreen = () => {
        const doc = document.documentElement;
        // if (doc.requestFullscreen) {
        //   doc.requestFullscreen();
        // } 
        // else 
        if (doc.mozRequestFullScreen) { // Firefox
            doc.mozRequestFullScreen();
        } else if (doc.webkitRequestFullscreen) { // Chrome, Safari and Opera
            doc.webkitRequestFullscreen();
        } else if (doc.msRequestFullscreen) { // IE/Edge
            doc.msRequestFullscreen();
        }
        setIsFullScreen(true);
    };

    const closeFullscreen = () => {
        // if (document.exitFullscreen) {
        //   document.exitFullscreen();
        // } else 
        if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
        setIsFullScreen(false);
    };

    //   useEffect(() => {

    //     openFullscreen();

    //   },[]);

    //   useEffect(() => {
    //     const handleBeforeUnload = (event) => {
    //       if (isFullScreen && !assessmentCompleted) {
    //         const message = "Are you sure you want to exit? Your assessment is not complete.";
    //         event.returnValue = message; // Standard for most browsers
    //         return message; // For some older browsers
    //       }
    //     };

    //     window.addEventListener('beforeunload', handleBeforeUnload);

    //     return () => {
    //       window.removeEventListener('beforeunload', handleBeforeUnload);
    //     };
    //   }, [isFullScreen, assessmentCompleted]);
    // console.log(questions.length,"ques",questions)
    const handleSelectedAns = (e) => {
        setSelected(e)
      
    }

    const handleAnswer = (index, answer) => {
        const newAnswers = [...answers];
        newAnswers[index] = answer;
        setAnswers(newAnswers);
      };
    
      

      return (
        <div className='mcqMainDiv'>
             {
                contextHolder
            }
            {currentQuestion < questions.length ? (
                <Row>
                    <Col span={16}>
                        <Card className='questionCard' title={`Question ${currentQuestion + 1}`} style={{ width: '100%' }}>
                            <p>{questions[currentQuestion]?.question}</p>
                            <Radio.Group
                                onChange={(e) => handleOptionSelect(e, questions[currentQuestion]?.question_id)}
                                value={selectedOption}
                            >
                                {questions[currentQuestion]?.options?.map((option, index) => (
                                    <Radio key={index} value={option}>
                                        {option}
                                    </Radio>
                                ))}
                            </Radio.Group>
                            <br />
                            <TextArea
                                rows={4}
                                placeholder="Enter Description"
                                value={description}
                                onChange={handleDescriptionChange}
                            />
                            <br />
                            <br />
                            <div className='nextQuestionDiv'>
                                {currentQuestion > 0 && (
                                    <Button type="primary" onClick={handlePreviousQuestion}>
                                        Previous Question
                                    </Button>
                                )}
                                {currentQuestion !== questions.length - 1 ? (
                                    <Button type="primary" onClick={handleNextQuestion}>
                                        Next Question
                                    </Button>
                                ) : (
                                    UserResponses.length === questions.length && (
                                        <Button type='primary' onClick={handleFinish}>
                                            Finish
                                        </Button>
                                    )
                                )}
                            </div>
                        </Card>
                    </Col>
                    <Col span={7} offset={1}>
    <Card style={{ width: '100%' }}>
        <div>
            <h4 style={{ marginTop: 0 }}>Question Charts</h4>
            {[...Array(Math.ceil(questions.length / 5)).keys()].map((rowIndex) => (
                <div key={rowIndex} style={{ display: 'flex', marginBottom: '10px' }}>
                    {questions.slice(rowIndex * 5, rowIndex * 5 + 5).map((_, index) => {
                        const questionId = rowIndex * 5 + index + 1;
                        const isAnswered = status[questionId - 1] === 'answered';
                        const isViewedUnanswered = status[questionId - 1] === 'viewed-unanswered';
                        const isSelected = currentQuestion === questionId - 1;

                        const itemStyle = {
                            width: '30px',
                            height: '30px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '50%',
                            padding: '10px',
                            cursor: 'pointer',
                            backgroundColor: isAnswered ? 'green' : isViewedUnanswered ? 'red' : 'transparent',
                            color: isAnswered || isViewedUnanswered ? 'white' : 'black',
                            border: isSelected ? '2px solid blue' : '1px solid #ddd',
                            marginRight: '10px',
                            marginBottom: '10px',
                            textAlign: 'center',
                        };

                        return (
                            <div
                                key={questionId}
                                onClick={() => handleSelectedQuestion(questionId)}
                                style={itemStyle}
                            >
                                <p style={{ margin: 0 }}>{questionId}</p>
                            </div>
                        );
                    })}
                </div>
            ))}
        </div>
    </Card>
</Col>
                </Row>
            ) : (
                <Card>
                    <p>There is no question here !!!</p>
                </Card>
            )}
        </div>
    );
}

export default McqWithDesc;