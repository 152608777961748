import './Mcq.css'
import React, { useState, useEffect } from 'react';
import { Radio, Button, Card, Typography, Col, Row, notification } from 'antd';
import { json, useNavigate, useParams } from 'react-router';
import { useStateValue } from '../../../../Reducer/reducer';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import axios from 'axios';
import { AssesmentUrl, BaseUrl } from '../../../Constants/Constants';
import Swal from 'sweetalert2';
import { actionTypes } from '../../../../Reducer/StateProvider'


const { Text } = Typography;

const TrainingMcq = ( ) => {

    const navigate = useNavigate();

    const [
        { UserID, Questions },
        dispatch
    ] = useStateValue();

    // console.log(Questions,"questions")

    const [questions, setQuestions] = useState(Questions)



    const [api, contextHolder] = notification.useNotification();
    const [mcq_id , setmcq_id]= useState("")


    const [status, setStatus] = useState(Array(questions.length).fill(''));
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [selectedOption, setSelectedOption] = useState('');
    const [timer, setTimer] = useState(1200); // 10 minutes in seconds
    const [BookmarkStatus, setBookmarkStatus] = useState(false)
    const history = useNavigate();
    const [Selected, setSelected] = useState('')
    const [UserResponses, setUserResponses] = useState([]);
    const [SelectedQuestionId, setSelectedQuestionId] = useState('')

    const { id, courseId } = useParams();
    const { assessmentId } = useParams();

    const cleanedString = assessmentId.replace(/\s+/g, ' ');
    let modUrl = cleanedString.toLowerCase().split(' ');
    let url = modUrl.join('-');
    let structuredUrl = url.split('/').join('-');

    const questionChart = questions.map((_, index) => index + 1);
    // const questionChart = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ];

    const rowsOfNumbers = [];
    for (let i = 0; i < questionChart.length; i += 5) {
        rowsOfNumbers.push(questionChart.slice(i, i + 5));
    }

    // console.log(timer, 'timerDetails')


    useEffect(() => {
        let body = {
            assessment_id: assessmentId
        }

        axios
            .post(`${BaseUrl}assesment/Assesment_details`,body)
            .then((res) => {

                let duration = JSON.parse(res.data.data.Assesment_Duration);

                setTimer(duration*60)
                // setData(JSON.parse(res.data.data.Assesment_Duration))
                console.log(typeof JSON.parse(res.data.data.Assesment_Duration) ,"len")
            })
            .catch((err) => {

            })
            .finally((res) => {

            })


    }, [])

    useEffect(() => {
        const countdown = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer === 0) {
                    clearInterval(countdown);
                    // Redirect to the result page or handle quiz completion
                    history(`/training/${id}/${courseId}/assessment/${structuredUrl}/mcq/result`);
                }
                return prevTimer - 1;
            });
        }, 1000);

        return () => clearInterval(countdown);
    }, [history]);


    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    };

    const handleOptionSelect = (e, id) => {
        console.log(id, 'channnnnnnid');
        const selectedOPtion = e.target.value;
        setSelectedOption(selectedOPtion);
    
        setUserResponses((prevResponses) => {
          const existingResponseIndex = prevResponses.findIndex(
            (response) => response.question_id === id
          );
          console.log(existingResponseIndex, "existingResponseIndex");
    
          if (existingResponseIndex !== -1) {
            const updatedResponses = [...prevResponses];
            updatedResponses[existingResponseIndex] = { question_id: id, answer: selectedOPtion };
            return updatedResponses;
          } else {
            return [...prevResponses, { question_id: id, answer: selectedOPtion }];
          }
        });
    
    };
    console.log( "channnn", UserResponses);
    // console.log(selectedOption, "selected")

    const openNotificationWithIconWarning = (type) => {
        api['warning']({
            message: "Warning!!!",
            description:
                type,  
        });
    };

    const openAlert = (type) => {
        Swal.fire({
            title: 'Alert!!!',
            text: type,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK',
            allowOutsideClick: false 
        }).then((result) => {
            if (result.isConfirmed) {
                // Perform your action here
               navigate(`/courses/${id}`)
            }
        });
    };


    useEffect(() => {
        if (UserResponses.length > 0) {
          const currentQuestionId = questions[currentQuestion]?.question_id;
          const existingResponse = UserResponses.find(
            (response) => response.question_id === currentQuestionId
          );
    
          if (existingResponse) {
            setSelectedOption(existingResponse.answer);
          } else {
            setSelectedOption(null);
          }
        }
      }, [currentQuestion, questions]);

    
    const handleNextQuestion = () => {
        setCurrentQuestion((prevQuestion) => prevQuestion + 1);
        
      };


    const handlePreviousQuestion = () => {
        // Move to the previous question
        setCurrentQuestion((prevQuestion) => Math.max(prevQuestion - 1, 0));
    };



    const handleFinish = () => {
            setUserResponses((prevResponses) => [...prevResponses, { "question_id": SelectedQuestionId, "answer": selectedOption }]);

            if (UserResponses.length == questions.length) {

                setAssessmentCompleted(true);
                let body = {
                    course_id: "",
                    user_id: UserID,
                    user_answer: UserResponses,
                    assessment_id : assessmentId,
                    training_id : id
                }
                axios
                    .post(`${AssesmentUrl}assessment_validation_training`, body)
                    .then((res) => {
                        console.log(res, "response")
                    
                        closeFullscreen();
                        history(`/training/${id}/${courseId}/assessment/${structuredUrl}/mcq/result`);
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            
        }
        else {
            openNotificationWithIconWarning('Select atleast one option');
        }

    }

    const HandleSlected = (e) => {
        let selectedNum = questions.find((i) => i.id == parseInt(e))
        // questions.push()
        console.log(e, "clicked", selectedNum)
    }
    const handleQuestionClick = (questionIndex, selectedOption) => {
        // Update the status based on the correctness of the selected option
        const isCorrect = questions[questionIndex].correctAnswer === selectedOption;
        const updatedStatus = [...status];
        updatedStatus[questionIndex] = isCorrect ? 'correct' : 'incorrect';
        setStatus(updatedStatus);
    };
    const HandleSelected = (questionId) => {
        // Move to the selected question
        setCurrentQuestion(questionId - 1);  
    };
    const questionTitle = (
        <div className='QuestionHeader'>
            <p>
                Question {currentQuestion + 1}
            </p>
            <Text className='Ques-txt'>Time Remaining: {formatTime(timer)}</Text>
            {/* <BookmarkBorderIcon 
                    className='Bookmark'
                                    onClick={()=> HandleBookmark(questions[currentQuestion]?.id)}
                                    /> */}
        </div>
    )
    const HandleBookmark = (e) => {
        const isBookmarked = e === currentQuestion - 1;
        setBookmarkStatus(isBookmarked)
    }
    useEffect(() => {
        const handleVisibilityChange = () => {
          if (document.visibilityState === 'hidden') {
            console.log("switched")
            // User switched to a different tab or minimized the window
            openAlert('Switching Tabs leads to end assesment');

            // navigate(`/courses/${id}`)



          }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
          // Cleanup the event listener when the component unmounts
          document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
      }, []);
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            // You can perform actions here before the page is unloaded/reloaded
            console.log('Page is about to be reloaded');

            // Optionally, you can show a confirmation message
            const confirmationMessage = 'Are you sure you want to leave?';
            event.returnValue = confirmationMessage;
            return confirmationMessage;
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            // Cleanup the event listener when the component unmounts
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);




    const [isFullScreen, setIsFullScreen] = useState(false);
    const [assessmentCompleted, setAssessmentCompleted] = useState(false);

    const openFullscreen = () => {
        const doc = document.documentElement;
        // if (doc.requestFullscreen) {
        //   doc.requestFullscreen();
        // } 
        // else 
        if (doc.mozRequestFullScreen) { // Firefox
            doc.mozRequestFullScreen();
        } else if (doc.webkitRequestFullscreen) { // Chrome, Safari and Opera
            doc.webkitRequestFullscreen();
        } else if (doc.msRequestFullscreen) { // IE/Edge
            doc.msRequestFullscreen();
        }
        setIsFullScreen(true);
    };

    const closeFullscreen = () => {
        // if (document.exitFullscreen) {
        //   document.exitFullscreen();
        // } else 
        if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
        setIsFullScreen(false);
    };

    //   useEffect(() => {

    //     openFullscreen();

    //   },[]);

    //   useEffect(() => {
    //     const handleBeforeUnload = (event) => {
    //       if (isFullScreen && !assessmentCompleted) {
    //         const message = "Are you sure you want to exit? Your assessment is not complete.";
    //         event.returnValue = message; // Standard for most browsers
    //         return message; // For some older browsers
    //       }
    //     };

    //     window.addEventListener('beforeunload', handleBeforeUnload);

    //     return () => {
    //       window.removeEventListener('beforeunload', handleBeforeUnload);
    //     };
    //   }, [isFullScreen, assessmentCompleted]);
    // console.log(questions.length,"ques",questions)
    const handleSelectedAns = (e) => {
        setSelected(e)
      
    }

    return (
        <div className='mcqMainDiv'>
            {
                contextHolder
            }
            {
                currentQuestion < questions.length ?
                    <Row>
                        <Col span={16}>
                            {/* <p className='back'
                        //  onClick={() => setshowTable(false)}
                        >
                            Team/{assessmentId}
                        </p> */}
                            <Card className='questionCard' title={questionTitle} style={{ width: '100%' }}>
                                <Typography.Paragraph className='questionParagraph'>{questions[currentQuestion]?.question}</Typography.Paragraph>
                                {/* <Button onClick={()=> HandleBookmark(questions[currentQuestion]?.id)}>
                                    Bookmark
                                </Button> */}

                                <Radio.Group onChange={(e)=> handleOptionSelect(e,questions[currentQuestion]?.question_id)} value={selectedOption}>
                                    {questions[currentQuestion]?.options?.map((option, index) => (
                                        <Radio key={index} onClick={(e) => handleSelectedAns(option)} value={option}>
                                            {option}
                                        </Radio>
                                    ))}
                                </Radio.Group>
                                <br />
                                <br />
                                <br />
                                <div
                                    className='nextQuestionDiv'>
                                    {currentQuestion > 0 ?
                                        <Button className='PrevBTN' type="primary" onClick={handlePreviousQuestion}>
                                            Previous Question
                                        </Button>
                                        :
                                        <></>
                                    }
                                    {
                                        currentQuestion !== questions.length - 1 ?
                                            <Button className='NextBTN' type="primary" onClick={handleNextQuestion}>
                                                Next Question
                                            </Button>
                                            :
                                            <>
                                            {
                                              UserResponses.length === questions.length ?
                                              <Button className='FinishBTN' type='primary' onClick={handleFinish}>
                                              Finish
                                          </Button>
                                          :
                                          <>
                                          </>
                                            
                                            }
                                            </>
                                           
                                    }
                                   
                                </div>
                            </Card>
                        </Col>
                        <Col span={7} offset={1}>
                            <Card style={{ width: '100%' }}>
                                <div>
                                    <h4 style={{ marginTop: 0 }}>Question Charts</h4>
                                    {rowsOfNumbers.map((row, rowIndex) => (
                                        <div key={rowIndex} className='questionChartItemsDiv'>
                                            {row.map((questionId) => (
                                                <div
                                                    key={questionId}
                                                    onClick={() => HandleSelected(questionId)}
                                                    // style={{ backgroundColor:selectedOption == questionId ? "red" : "orange"}}
                                                    className={currentQuestion + 1 === questionId ? `questionChartItemsSelected` : `questionChartItems`}
                                                >
                                                    <p>{questionId}</p>
                                                </div>
                                            ))}
                                        </div>
                                    ))
                                    }
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    :
                    <>
                        <Card>
                            <p>There is no question here !!!</p>
                        </Card>
                    </>
            }
        </div>
    )
}

export default TrainingMcq