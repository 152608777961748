
// export const BaseUrl = `https://api-training-nexus.valuehealthsolutions.com/`
// export const BaseUrl = 'https://nestapi.dev.training-nexus.healthitplatform.com/'
// export const BaseUrl = `http://localhost:9009/`
export const BaseUrl = process.env.REACT_APP_BASEURL

// export const AssesmentUrl ='https://py3api.dev.training-nexus.healthitplatform.com/'
export const AssesmentUrl =process.env.REACT_APP_ASSESSMENTURL

// export const CommuncationBot = `https://hiringtalentpro.valuehealthsolutions.com`
export const CommuncationBot = process.env.REACT_APP_COMMUNICATIONBOT
