// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-container{
  max-width: 1380px;
  margin: 0 auto;
  min-height: 90vh;
  /* background-color: aquamarine; */
}
.Loader img{
  width: 70px;
}
.Loader{
  text-align: center;
}
/* .botImageClass{
  cursor: pointer;
  height: 50px;
  width: auto;
  float: right;
  margin: 0px 22px 18px 0px;
  border-radius: 13px;
} */
.botImageClass{
  cursor: pointer;
  height: 50px;
  width: auto;
  border-radius: 13px;
  position: fixed;
  right: 25px;
  bottom: 55px;
  z-index: 999;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,cAAc;EACd,gBAAgB;EAChB,kCAAkC;AACpC;AACA;EACE,WAAW;AACb;AACA;EACE,kBAAkB;AACpB;AACA;;;;;;;GAOG;AACH;EACE,eAAe;EACf,YAAY;EACZ,WAAW;EACX,mBAAmB;EACnB,eAAe;EACf,WAAW;EACX,YAAY;EACZ,YAAY;AACd","sourcesContent":[".main-container{\n  max-width: 1380px;\n  margin: 0 auto;\n  min-height: 90vh;\n  /* background-color: aquamarine; */\n}\n.Loader img{\n  width: 70px;\n}\n.Loader{\n  text-align: center;\n}\n/* .botImageClass{\n  cursor: pointer;\n  height: 50px;\n  width: auto;\n  float: right;\n  margin: 0px 22px 18px 0px;\n  border-radius: 13px;\n} */\n.botImageClass{\n  cursor: pointer;\n  height: 50px;\n  width: auto;\n  border-radius: 13px;\n  position: fixed;\n  right: 25px;\n  bottom: 55px;\n  z-index: 999;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
