import React, { useRef, useState, useEffect } from 'react'
import { LaptopOutlined, NotificationOutlined, UserOutlined } from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, Col, Row } from 'antd';
import './TrainingModulePage.css'
import ReactPlayer from 'react-player'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useParams, useNavigate } from 'react-router';
import { Player, ReplayControl, ControlBar, BigPlayButton } from 'video-react';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import axios from 'axios';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { Document, Page, pdfjs } from "react-pdf";
import ArticleIcon from '@mui/icons-material/Article';
import { BaseUrl } from '../../Constants/Constants';


const TrainingModulePage = () => {
    const { Header, Content, Sider } = Layout;
    const [courseName, setCourseName] = useState("");
    const [MaterialName, setMaterialName] = useState('');
    const [trainingMenu, settrainingMenu] = useState([]);
    const [loading, setLoading] = useState(false);
    const [PlayedSeconds, setPlayedSeconds] = useState('')
    const [Type, setType] = useState('')
    const [TotalDuration, setTotalDuration] = useState("")
    const [TrainingModule, setTrainingModule] = useState("")
    const [DocUrl, setDocUrl] = useState('')




    let url = window.location.href;
    const { id, courseId } = useParams();
    const { moduleId } = useParams();
    const navigate = useNavigate();

    console.log(MaterialName, 'MaterialName')

    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


    const [numPages, setNumPages] = useState(null);

    const onDocumentLoadSuccess = ({ numPages }) => {
        //   setNumPages(numPages);
    };

    useEffect(() => {
        setLoading(true);

        let body = {
            training_id: id
        }

        axios.post(`${BaseUrl}trainings/findOneTraining`, body)

            .then((res) => {

                let response = res.data;

                if (response.statuscode === 200) {
                    console.log(res.data.data, 'courseDetails');
                    setCourseName(response.data.training_title);
                }
                else {
                    setCourseName('');
                }
            })
            .catch((err) => {
                console.log(err.message)
            })


        let trainingBody = {
            training_file_id: moduleId
        }

        axios.post(`${BaseUrl}training-module/findOne`, trainingBody)
            .then((res) => {
                console.log(res.data, 'hhhh')
                if (res.data.statuscode === 200) {
                    setMaterialName(res.data.data.training_module_name);
                    setTrainingModule(res.data.data.training_module)
                    setType(res.data.data.training_module_type)
                    if (res.data.data.training_module_type == "pdf") {

                        setDocUrl(res.data.data.training_module)
                    }

                } else {
                    setMaterialName('Training Material Not found!')
                }
            })
            .catch((err) => {
                console.log(err.message)
            })

            let courseBody = {
                courseId : courseId
            }

            axios.post(`${BaseUrl}course-parts/findAllModulesByCourseId`, courseBody)
            .then((res) => {

                let response = res.data;
                console.log(response, 'chan')

                if (response.statuscode === 200) {
                    let arr = []
                    let transformed = response.data.map((item) => {
                        if (item.type === "mp4") {
                            let obj = {
                                key: item.key,
                                label: item.label,
                                time: item.time,
                                type: item.type,
                                icon: <PlayCircleOutlinedIcon />
                            }
                            arr.push(obj)
                        }
                        if (item.type === "") {
                            let obj = {
                                key: item.key,
                                label: item.label,
                                time: item.time,
                                type: item.type,
                                icon: <ArticleIcon />
                            }
                            arr.push(obj)
                        }
                        if (item.type === "pdf") {
                            let obj = {
                                key: item.key,
                                label: item.label,
                                time: item.time,
                                type: item.type,
                                icon: <ArticleIcon />
                            }
                            arr.push(obj)
                        }

                        settrainingMenu(arr)
                        console.log(arr, "arrrr")
                    })
                } else {
                    settrainingMenu([]);
                }
            })
            .catch((err) => {
                console.log(err.message)
            })
            .finally(() => {
                setLoading(false);
            })

    }, [moduleId])


    const playerConfig = {
        file: {
            attributes: {
                controlsList: 'nodownload', // Disable the download button
            },
        },
    };

    const handleClick = (e) => {
        console.log(e, 'courseSlider');

        navigate(`/training/${id}/${courseId}/pages/${e.key}`);

        // const itemfound = items2.find(item => parseInt(e.key) === item.key)
        // console.log(itemfound, "found")
        // if (itemfound) {
        //     setMaterialName(itemfound.label)
        // } else {
        //     setMaterialName("Course not available!")
        // }
    }

    const handleProgress = (progress) => {
        setPlayedSeconds(progress.playedSeconds);
        console.log(progress.playedSeconds, "progress")
        // You can perform additional actions based on the progress if needed
    };
    const handleDuration = (duration) => {
        setTotalDuration(duration);
        console.log(duration, "duration")
    };

    return (
        <div className='Pages-main'>
            <p className='back'>
                <span onClick={(e) => navigate(`/training`)} style={{ color: '#4096FF', textDecoration: "underline" }}>
                    courses
                </span>
                <span>
                    <ArrowForwardIosIcon className='arrow' />
                </span>
                {courseName && <span onClick={(e) => navigate(`/training/${id}/${courseId}`)} style={{ color: '#4096FF', textDecoration: "underline" }}>
                    {courseName}
                </span>}
                <span>
                    <ArrowForwardIosIcon className='arrow' />
                </span>
                {MaterialName && <span style={{ color: '#454545', textDecoration: "underline" }}>
                    {MaterialName}
                </span>}
            </p>
            <Row>
                <Col span={4}>
                    <h2>
                        Course Contents
                    </h2>

                    <Sider
                        width={250}
                        style={{
                            background: "#fff",
                        }}
                        className='Slider'
                    >
                        <Menu
                            mode="inline"
                            // defaultSelectedKeys={['1']}
                            defaultOpenKeys={['sub1']}
                            style={{
                                height: '100%',
                                borderRight: 0,
                            }}
                            onSelect={(e) => handleClick(e)}
                            items={trainingMenu}
                        />
                    </Sider>
                </Col>
                <Col offset={2} span={18}>
                    {courseName ?  
                    <h1 className='MaterialName' >
                        {courseName} - {MaterialName}
                    </h1>
                    : <></>
}

                    {Type === "mp4" ?

                        <div className='videoDiv'>

                            <ReactPlayer
                                width={900}
                                height={400}
                                controls
                                config={playerConfig}
                                onDuration={handleDuration}
                                onProgress={handleProgress}
                                url={TrainingModule}
                            // url='https://media.w3.org/2010/05/sintel/trailer_hd.mp4'
                            // url='s3://preciumweb-dev/dev/Introduction to CT Chest - Anatomy and Approach.mp4'
                            />
                        </div>
                        :
                        <div className='Doc'>


                            {
                                DocUrl != "" ?

                                    <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}>
                                        <Viewer fileUrl={DocUrl} />
                                    </Worker>
                                    :
                                    <div className='Loader' >
                                        <img src='/Loader/Ellipsis-2.9s-200px.svg'>
                                        </img>
                                    </div>
                            }

                        </div>
                    }
                </Col>
            </Row>
        </div>
    )
}

export default TrainingModulePage