import React, { useState, useEffect } from 'react'
import { Row, Col, Card, Button, Alert, Menu, Avatar, Tabs } from 'antd'
import './Dashboard.css';
import { HeatMapOutlined, CommentOutlined, PaperClipOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { AssesmentUrl } from '../Constants/Constants';
import axios from 'axios';
import { Table } from 'antd'
import { useLocation } from 'react-router-dom';
import { notification } from 'antd';



const Dashboard = () => {

    const navigate = useNavigate();
    const [allData, setAllData] = useState([])
    const [email, setEmail] = useState('');
    const location = useLocation();

    useEffect(() => {
        const profile = localStorage.getItem('Profile');
        if (profile) {
            const parsedProfile = JSON.parse(profile);
            setEmail(parsedProfile.email);
            console.log(email, "email")
        }
    }, []);

    useEffect(() => {
        if (location.state?.message) {
            notification.success({
                message: "Success",
                description: location.state.message,
            });
        }
    }, [location]);
    const handleClickOngoing = () => {
        navigate(`/courses`)
    }
    const handleClickCompleted = () => {
        navigate(`/courses`)
    }

    const [current, setCurrent] = useState('mail');
    const onClick = (e) => {
        console.log('click ', e);
        setCurrent(e.key);
    };
    const HandleClick = () => {

        navigate(`/gradebook`)
    }
    const onChange = (key) => {
        console.log(key);
    };


    //   scores

    // useEffect(() => {
    //     if (email) {
    //         axios.get(`${AssesmentUrl}all_score`)
    //             .then((res) => {
    //                 // const filteredData = res.data.score.filter(item => item.email === email && item.score !== null || "nan" ||" ");
    //                 const filteredData = res.data.score.filter(item => {
    //                     return item.email === email &&
    //                         item.score !== null &&
    //                         item.score !== undefined &&
    //                         item.score !== "" &&
    //                         !isNaN(item.score);
    //                 });

    //                 setAllData(filteredData);
    //             })
    //             .catch((err) => {
    //                 console.log("Error fetching scores:", err);
    //             });
    //     }
    // }, [email]);


    const columns = [
        {
            title: 'Training Name',
            dataIndex: 'training_title',
            key: 'course_title',
            align: 'center',
        },
        {
            title: 'Score',
            dataIndex: 'score',
            key: 'score',
            align: 'center',
        },
        // {
        //     title: 'Email',
        //     dataIndex: 'email',
        //     key: 'email',
        //     align: 'center',
        // },
    ];

    const dataSource = allData?.map((data, index) => ({
        key: index + 1,
        serial: index + 1,
        username: data.username,
        email: data.email,
        no_of_question: data.no_of_question,
        score: data.score,
        course_title: data.course_title,
        date: data.date,
    }));


    const items = [
        {
            key: '1',
            label: 'Upcoming Trainings',
            children:
                <div className='Upcomming'>
                    {/* <p style={{margin:0,float:"right"}}>
            ETA : 11:00
            </p> */}
                    {/* <p className='CollapseH1'> No Trainings Assigned!</p> */}
                    <Table dataSource={allData} columns={columns} />
                    {/* <h4 style={{margin:0}}>-</h4> */}
                </div>
            ,
        },
        {
            key: '2',
            label: 'Ongoing Trainings',
            children: <div onClick={handleClickOngoing} className='Upcomming'>
                {/* <p style={{margin:0,float:"right"}}>
                  ETA : 7:00 
                  </p> */}
                {/* <p className='CollapseH1'> No Trainings Assigned!</p> */}
                <Table dataSource={allData} columns={columns} />
                {/* <h4 style={{margin:0}}>Communication Course</h4> */}
            </div>
        },
        {
            key: '3',
            label: 'Completed Trainings',
            children: <div className='Upcomming'>
                {/* <p style={{margin:0,float:"right"}}>
                  ETA : 10:00 
                  </p> */}
                {/* <p className='CollapseH1'> No Trainings Assigned!</p> */}
                <Table dataSource={allData} columns={columns} />

                {/* <h4 style={{margin:0}}>-</h4> */}
            </div>
        },
    ];
    return (
        <div
            className='Dashboard-main'
        >

            <Row>
                <Col xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 11 }}
                    lg={{ span: 11 }}
                    xl={{ span: 11 }}
                    xxl={{ span: 11 }}
                >
                    <Card style={{ marginBottom: 20 }} className='Rpconect-card'>

                        <div className='rp-card-div'>

                            <img className='Rpconect-card-image' src='/icons/7995182.jpg' />

                            <h2 className='rp-h2'>
                                Community Connect
                            </h2>
                            <img className='' src='/icons/coming-soon-1.png' alt='comingsoon' width={35} height={35} />

                            <p className='rp-p'>
                                Platform to Connect with Community
                            </p>
                            <Button className='Rp-button' type='primary'>
                                Explore Now
                            </Button>
                        </div>



                    </Card>

                    <Card className='activitiesCardClass' style={{ marginBottom: 20 }}>
                        <h2 style={{ margin: 0 }}>
                            Activities
                        </h2>
                        <Alert
                            banner
                            message='All time information shown below is in IST format.'


                        />
                        <div >
                            <Tabs defaultActiveKey="1"
                                items={items}
                                centered
                                onChange={onChange} />

                        </div>

                    </Card>
                    <Card className='industryArticlesCard' style={{ marginBottom: 20 }}>
                        <div className='Articles-main'>
                            <Avatar
                                size={64}
                                style={{
                                    backgroundColor: '#1b34cca8',
                                }}
                                icon={<PaperClipOutlined color='#878eff' />}
                            />
                            <h2 className='Attendance'>
                                Industry Articles
                            </h2>
                            <div className='comingsoonParent'>
                                <img className='' src='/icons/coming-soon-1.png' alt='comingsoon' width={35} height={35} />
                            </div>
                        </div>
                        <p className='Articles-p'>
                            Curated articles on latest developments and trends
                        </p>
                        <Button className='Readmore' type='link'>
                            Read More..
                        </Button>
                    </Card>
                </Col>
                <Col xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 8 }}
                    lg={{ span: 12 }}
                    xl={{ span: 12 }}
                    xxl={{ span: 12 }}
                    offset={1}
                >
                    <Card style={{ marginBottom: 20 }} className='Progress-card'>
                        <div style={{ display: 'flex' }}>
                            <h2>My Courses & Trainings</h2>
                            <img className='comingSoonImages' src='/icons/coming-soon-1.png' alt='comingsoon' width={35} height={35} />
                        </div>
                        <div className='Progress'>
                            <div>
                                <p className='progress-p'>In Progress</p>
                                <h2 className='progress-h2'>
                                    -
                                </h2>
                            </div>
                            <div>
                                <p className='progress-p'>Completed</p>
                                <h2 className='progress-h2'>
                                    -
                                </h2>
                            </div>
                            <div>
                                <p className='progress-p'>Incomplete</p>
                                <h2 className='progress-h2'>
                                    -
                                </h2>
                            </div>
                        </div>
                        {/* <div className='view-gradebook' >

                            <Button onClick={HandleClick} type='link'>
                                View GradeBook
                            </Button>
                        </div> */}
                    </Card>
                    <Card style={{ marginBottom: 20 }} className='att-card'>
                        <div className='attendance-main'>
                            <Avatar
                                size={64}
                                style={{
                                    backgroundColor: '#7f6cd499',
                                }}
                                icon={<HeatMapOutlined color='#878eff' />}
                            />
                            <div>

                                <h2 className='Attendance'>
                                    Average User Participation Rate
                                </h2>
                                <h1 className='Attendance-percent'>
                                    77.3%
                                </h1>
                                <p className='Attendance-p'>
                                    Top learners have above 90%
                                </p>
                            </div>
                        </div>

                    </Card>
                    <Card style={{ marginBottom: 20 }}>
                        <div className='Assistance-main'>
                            <Avatar
                                size={64}
                                style={{
                                    backgroundColor: '#39982c75',
                                }}
                                icon={<CommentOutlined color='#878eff' />}
                            />
                            <h2 className='Attendance'>
                                Need Assistance?
                            </h2>
                            {/* <div className='comingsoonParent'>
                            <img className='' src='/icons/coming-soon-1.png' alt='comingsoon' width={35} height={35} />
                            </div>  */}
                        </div>
                        <p className='Articles-p'>
                            We are here to help. <a href='https://uatprecium.widget.healthitplatform.com/1af46a18-4d5b-4fb1-afbc-9f61a0d50cb2' target='_blank'> Click here </a> to reach out to us
                        </p>

                    </Card>
                    <Card style={{ marginBottom: 20 }}>
                        <div className='Assistance-main'>
                            <Avatar
                                size={64}
                                style={{
                                    backgroundColor: '#39982c75',
                                }}
                                icon={<CommentOutlined color='#878eff' />}
                            />
                            <h2 className='Attendance'>
                                Know Your Radiologist
                            </h2>
                            <img className='comingSoonImageInKnowRadiologist' src='/icons/coming-soon-1.png' alt='comingsoon' width={35} height={35} />
                            {/* <div className='comingsoonParent'>
                            <img className='' src='/icons/coming-soon-1.png' alt='comingsoon' width={35} height={35} />
                            </div>  */}
                        </div>
                        <p className='Articles-p'>
                            We are here to help. <a> Click here </a> to reach out to us
                        </p>
                        {/* <p className='Articles-p'>
                            We are here to help. <a href='https://uatprecium.widget.healthitplatform.com/0a05de51-acc3-47a5-9835-bacedcb79da4' target='_blank'> Click here </a> to reach out to us
                        </p> */}

                    </Card>

                    
                </Col>
            </Row>

        </div>
    )
}

export default Dashboard