import React from 'react'
import './Footer.css'
import { Avatar, Col, Row, Input } from 'antd'

const Footer = () => {

  let url = window.location.href;
  let splittedUrl = url.split('/')[3];



  return (
    <>
      {splittedUrl === 'login' || splittedUrl === 'signup' || splittedUrl === 'forgotpassword' ?
        <></>
        :
        <footer className='footer'>
          <div>
            <Row>
              <Col span={6}>
                <img src='/Logo/Logo Svg OG 3.png' alt='' width={150} height={35} />
              </Col>
            </Row>
          </div>
        </footer>
      }
    </>
  )
}

export default Footer