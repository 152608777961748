import React, { useEffect, useState } from 'react'
import './CourseModule.css'
import { useNavigate, useParams } from 'react-router';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Col, Row, message,Progress } from 'antd';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import axios from 'axios';
import { BaseUrl } from '../../Constants/Constants';
import ArticleIcon from '@mui/icons-material/Article';

const CourseModule = () => {

    const [courseName, setCourseName] = useState("");
    const [expanded, setExpanded] = React.useState(false);
    const [expandedAss, setExpandedAss] = React.useState(false);
    const [courseModule, setCourseModule] = useState([]);
    const [loading, setLoading] = useState(false);
    const [Assesment, setAssesment] = useState('')

    const navigate = useNavigate();

    const profileDetails = JSON.parse(localStorage.getItem('Profile'));
    const UserId = profileDetails.user_id;

    console.log(courseModule, 'courseModule')

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const handleAssessmentAccordion = (panel) => (event, isExpanded) => {
        setExpandedAss(isExpanded ? panel : false);
    };

    const { id } = useParams();


    useEffect(() => {

        setLoading(true);

        let body = {
            courseId: id
        }

        axios.post(`${BaseUrl}courses/findOne`, body)

            .then((res) => {

                let response = res.data;

                if (response.statuscode === 200) {
                    console.log(res.data.data[0], 'courseDetails');
                    setCourseName(response.data[0].course_title);
                }
                else {
                    setCourseName('');
                }
            })
            .catch((err) => {
                console.log(err.message)
            })


        axios.post(`${BaseUrl}course-parts/findOneByCourseId`, body)
            .then((res) => {
                let response = res.data
                console.log(response.data, 'findOneByCourseId');

                if (response.statuscode === 200) {
                    setCourseModule(response.data);
                }

            })
            .catch((err) => {
                console.log(err.message)
                // setLoading(false);
            })
            .finally(() => {
                setLoading(false);
            })


            let trackerBody = {
                userId : UserId
            }

            axios.post(`${BaseUrl}course-tracker/getAllCourseTrackerByUserId`, trackerBody)
            .then(( res ) => {
                console.log(res.data,'trackerResp')
            })

    }, [])





    useEffect(() => {
        let body = {
            course_id: id
        }
        axios
            .post(`${BaseUrl}assesment/list_of_assessments`, body)
            .then((res) => {
                setAssesment(res.data.data)
                console.log(res.data.data, "listOfAssessments")
            })
            .catch((err) => {
                message.error(err.message);
            })
            .finally((res) => {

            })
    }, [])






    const assessmentData = [
        {
            icon: <EditNoteOutlinedIcon />,
            testName: `${courseName} assessment`,
            marks: "9/10",
            status: <CheckCircleOutlineOutlinedIcon />
        }
    ]






    return (
        <div className='courseModuleMainDiv'>
            <p className='back'>
                <span onClick={(e) => navigate(`/courses`)} style={{ color: '#4096FF', textDecoration: "underline" }}>
                    Courses
                </span>
                <span>
                    <ArrowForwardIosIcon className='arrow' />
                </span>
                {courseName && <span style={{ color: '#454545', textDecoration: "underline" }}>
                    {courseName}
                </span>}
            </p>
            {
                loading ?

                    <>
                        <div className='Loader' >
                            <img src='/Loader/Ellipsis-2.9s-200px.svg'>
                            </img>
                        </div>
                    </>
                    :
                    <>
                        <div className='courseHeadingDiv'>
                            <h1 className='courseHeading'>{courseName}</h1>
                        </div>

                        <div className='coursesAccordion'>

                            {
                                courseModule.map((item, key) => {
                                    console.log(item, 'mappedItems');
                                    return (
                                        <Accordion expanded={expanded === `panel${key}`} onChange={handleChange(`panel${key}`)}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                            >
                                                <p className='accordionHeading'>{item.partNumber} - {item.partTitle}</p>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div className='courseSourceDiv'>
                                                    {item.training.map((e, k) => (
                                                        <Row
                                                            onClick={() => {
                                                                navigate(`/courses/${id}/pages/${e.id}`);
                                                            }}
                                                            className='courseSourceTypes'>
                                                            <Col span={1} className='sourceIcon'>
                                                                {
                                                                    e.type === 'mp4' ?
                                                                        <PlayCircleOutlinedIcon />
                                                                        :
                                                                        <ArticleIcon />
                                                                }
                                                            </Col>
                                                            <Col span={18} className='courseSourceName'>
                                                                <p>{e.name}</p>
                                                            </Col>
                                                            <Col span={1} className='videoTime'>
                                                                <p>{e.time}</p>
                                                            </Col>
                                                            <Col span={2} className='sourceStatuss'>
                                                                {e.type === 'mp4' ?
                                                                    "Video" : "Document"
                                                                }
                                                            </Col>
                                                            <Col span={2}>
                                                            
                                                            <Progress style={{marginTop:10}}  width={30} height={30} type="circle" percent={100} />
                                                            </Col>
                                                        </Row>
                                                    ))
                                                    }

                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    )
                                })

                            }
                        </div>
                        {
                            Assesment.length != 0 ?

                                <>

                                    <div className='assessmentHeadingDiv'>
                                        <h1 className='assessmentHeading'>Assessment</h1>
                                    </div>
                                    <div className='assessmentAccordion'>


                                        <Accordion 
                                        // expanded={expandedAss === `panel${key}`} onChange={handleAssessmentAccordion(`panel${key}`)}
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                            >
                                                {/* <p className='accordionHeading'>{courseName}</p> */}
                                                <p className='accordionHeading'>Click here to take the assessment!</p>
                                            </AccordionSummary>
                                            {
                                                Assesment.map((item, key) => {
                                                    // console.log(courseName, 'mappedItems');
                                                    return (
                                                        <AccordionDetails>
                                                            <div className='courseSourceDiv'>
                                                                <Row
                                                                    onClick={() => {
                                                                        // console.log(item, 'structuredUrl')
                                                                        navigate(`/courses/${id}/assessment/${item.Assesment_id}`);
                                                                    }}
                                                                    className='courseSourceTypes'>
                                                                    <Col span={1} className='assessmentIcon'>
                                                                        {/* {item.icon} */}
                                                                        <EditNoteOutlinedIcon />
                                                                    </Col>
                                                                    <Col span={18} className='courseSourceName'>
                                                                        <p>{item.Assessment_name}</p>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </AccordionDetails>
                                                    )
                                                })
                                            }
                                        </Accordion>

                                    </div>

                                </>
                                : <></>}
                        {/* <p>
                                    
                                    No Assesments
                                </p> */}
                    </>
            }
        </div>
    )
}

export default CourseModule